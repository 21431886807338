import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert';
import ArticleIcon from '@mui/icons-material/Article';
import { set } from 'date-fns';

const PaymentUpload = ({ id, document }) => {
  const state = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [transactionId, settransactionId] = useState('');
  const [transactionname, settransacctionname] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
    }
  };

  const getPaymentFolders = (documents) => {
    const uniqueFolders = new Set();
    documents
      .filter((doc) => doc.documentType === 'payment')
      .forEach((doc) => {
        if (!uniqueFolders.has(doc.payment_folder)) {
          uniqueFolders.add(doc.payment_folder);
        }
      });
    return Array.from(uniqueFolders);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    // Form data for submission
    const formData = new FormData();
    if (file) {
      formData.append('payment', file);
    }

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/payment-document`,
        formData,
        {
          params: {
            id: id,
            payment_folder: transactionname,
            transaction_name: transactionId,
          },
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // Clear the form
        settransactionId('');
        settransacctionname('');
        setFile(null);
        setLoading(false);
        swal({
          title: 'Invoice Uploaded',
          icon: 'success',
          text: 'Payment Invoice uploaded successfully',
          button: true,
        });
      })
      .catch((err) => {
        swal({
          title: 'Invoice Upload Failed',
          icon: 'error',
          text: err.toString(),
          button: true,
        });
      });
  };

  const handleCancel = () => {
    settransactionId('');
    settransacctionname('');
    setFile(null);
    setFileName('');
  };

  const styles = {
    container: {
      width: '100%',
      maxWidth: '500px',
      marginTop: '30px',
      padding: '20px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
    },
    formGroup: {
      marginBottom: '20px',
    },
    input: {
      width: '100%',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '16px',
    },
    datalistOption: {
      padding: '10px',
      borderBottom: '1px solid #ddd',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      <h2>Upload Payment Invoice</h2>
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label>Transaction Name </label>
          <input
            type="text"
            value={transactionname}
            onChange={(e) => settransacctionname(e.target.value)}
            required
            style={styles.input}
            placeholder='Enter name of the transaction'
            list="transaction-options"
          />
          <datalist id="transaction-options">
            {getPaymentFolders(document).map((folder, index) => (
              <option key={index} value={folder} style={styles.datalistOption} />
            ))}
          </datalist>
        </div>
        <div style={styles.formGroup}>
          <label>Transaction ID </label>
          <input
            type="text"
            value={transactionId}
            onChange={(e) => settransactionId(e.target.value)}
            required
            style={styles.input}
            placeholder="Enter transaction ID"
          />
        </div>
        <div style={styles.formGroup}>
          <label>Upload File: </label>
          <div>
      <Button
        component="label"
        startIcon={<ArticleIcon />}
        sx={{
          textTransform: 'none',
          color: '#339193',
          padding: '4px 8px',
          fontSize: '0.875rem',
          border: '1px solid #339193',
          minHeight: '32px',
          marginRight: '10px',
        }}
      >
        Upload Document{' '}
        <input
          type="file"
          hidden
          onChange={handleFileChange}
          required
          style={styles.input}
        />
      </Button>
      {fileName && <span>{fileName}</span>}
    </div>
        </div>
        <div style={styles.formGroup}>
          <Button
            component={LoadingButton}
            loading={loading}
            variant="contained"
            type="submit"
            size="small"
            sx={{ maxWidth: 100 }}
          >
            {!loading && 'Upload'}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
            size="large"
            sx={{ maxWidth: 150, marginLeft: 2 }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PaymentUpload;
