import { Divider, Radio, Stack, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useEffect, useState } from 'react';
import { SelectFormik } from '../SelectCustom/SelectFormik';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTirnuAppContext } from 'context/ThemeContext';
import { useGetLedgersOptions } from 'services/Ledger';

const TransferMethods = ({
  onSelectLedger,
  selectedMethod,
  onSelectMethod,
}) => {
  const { Ledger } = useTirnuAppContext();
  const formik = useFormik({
    initialValues: {
      chooseAcc: Ledger?.ledger_id || '',
    },
  });
  const { ledgersOptions } = useGetLedgersOptions();

  const methods = [
    // {
    //   icon: <CreditCardIcon />,
    //   id: 'debit',
    //   title: 'Debit Card',
    //   desc: 'Pay using Visa or Mastercard',
    // },
    {
      icon: <AccountBalanceIcon />,
      id: 'bank',
      title: 'Transfer money using Bank account',
      desc: 'Transfer the money to Tirnu using your bank account',
    },
    {
      icon: <AccountCircleIcon />,
      id: 'ledger',
      title: 'Ledger',
      desc: 'Pay using Tirnu ledger',
    },
  ];

  useEffect(() => {
    onSelectLedger(formik.values.chooseAcc);
  }, [formik.values.chooseAcc]);

  return (
    <Stack direction="column" gap={1.5} mt={2}>
      <Divider />
      {methods.map((method) => (
        <Stack direction="row" key={method.id} justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            {method.icon}
            <Stack direction="column">
              <Typography variant="body2">{method.title}</Typography>
              <Typography variant="caption">{method.desc}</Typography>
            </Stack>
          </Stack>
          <Radio
            value={method.id}
            checked={selectedMethod === method.id}
            onChange={(e) => onSelectMethod(e.target.value)}
            sx={{ color: 'inherit' }}
          />
        </Stack>
      ))}

      <FormikProvider value={formik}>
        <Form>
          {selectedMethod === 'ledger' && (
            <SelectFormik
              placeholder="Choose an account"
              label="Transfer from"
              name="chooseAcc"
              options={ledgersOptions || []}
              enablePortal
            />
          )}
        </Form>
      </FormikProvider>
    </Stack>
  );
};

export default TransferMethods;
