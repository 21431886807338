import {
  Box,
  Button,
  FormHelperText,
  Typography,
  useTheme,
} from '@mui/material';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { InputLabel } from 'jsx/components/TextFieldFormik/styles';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import SelectMenu from 'jsx/components/UI/Select';
import { useFetchCountries, useValidateEmail } from '../../../../services/User';
import { useEffect, useState, useContext } from 'react';
import { ThemeContext } from '../../../../context/ThemeContext';
import debounce from 'lodash.debounce';
import { useUpdateFees } from '../../../../services/User';

const validationSchema = yup.object().shape({
  PrimaryFee: yup
    .number()
    .required('Primary Fee  is required')
    .positive('Primary Fee  must be a positive number'),
  PrimaryFeeType: yup
    .string()
    .oneOf(
      ['percentage', 'absolute'],
      'Fee must be either percentage or absolute'
    )
    .required('required'),
  SecondaryFee: yup
    .number()
    .required('Secondary Fee  is required')
    .positive('Secondary Fee  must be a positive number'),
  SecondaryFeeType: yup
    .string()
    .oneOf(
      ['percentage', 'absolute'],
      'Fee must be either percentage or absolute'
    )
    .required('required'),
  MonthlyFee: yup
    .number()
    .required('Monthly Fee  is required')
    .positive('Monthly Fee  must be a positive number'),
  MonthlyFeeType: yup
    .string()
    .oneOf(
      ['percentage', 'absolute'],
      'Fee must be either percentage or absolute'
    )
    .required('required'),
});

function UpdateFee({ id, fees }) {
  const { palette } = useTheme();
  const { state } = useContext(ThemeContext);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const UpdateFees = useUpdateFees();

  const onSubmit = async (data) => {
    try {
      await SubmitFeeInfo({
        _id: id,
        primaryFee: data.PrimaryFee,
        primaryFeeType: data.PrimaryFeeType,
        secondaryFee: data.SecondaryFee,
        secondaryFeeType: data.SecondaryFeeType,
        monthlyFee: data.MonthlyFee,
        monthlyFeeType: data.MonthlyFeeType,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const formikBag = useFormik({
    initialValues: {
      PrimaryFee: '',
      PrimaryFeeType: 'percentage',
      SecondaryFee: '',
      SecondaryFeeType: 'percentage',
      MonthlyFee: '',
      MonthlyFeeType: 'absolute',
    },
    validationSchema,
    onSubmit,
  });

  const SubmitFeeInfo = async (data) => {
    setShowLoading(true);
    try {
      await UpdateFees.mutateAsync(data);
      swal({
        title: 'Success',
        text: 'Fees updated successfully',
        icon: 'success',
        button: true,
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  };

  const FeesType = [
    { label: '%', value: 'percentage' },
    { label: '€', value: 'absolute' },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '500px',
        marginTop: '30px',
        padding: '20px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Typography id="modal-modal-title" variant="h6" component="h2">
        <h2>Update Fees</h2>
      </Typography>
      <Box mt={2}>
        <FormikProvider value={formikBag}>
          <Form onSubmit={formikBag.handleSubmit} style={{ width: '100%' }}>
            <Grid
              container
              spacing={2}
              sx={{ width: '100%', marginBottom: '12px' }}
            >
              <Grid item xs={20} tabletS={16}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Grid item xs={6} tabletS={2}>
                    <InputLabel>Type</InputLabel>
                    <SelectMenu
                      options={FeesType}
                      value={FeesType.find(
                        (option) =>
                          option.value === formikBag.values.PrimaryFeeType
                      )}
                      onChange={(option) =>
                        formikBag.setFieldValue('PrimaryFeeType', option?.value)
                      }
                      isRounded
                    />
                    {formikBag.errors.PrimaryFeeType &&
                      formikBag.touched.PrimaryFeeType && (
                        <FormHelperText error>
                          {formikBag.errors.PrimaryFeeType}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={12} tabletS={10}>
                    <TextFieldFormik
                      label="Primary Fee"
                      type="number"
                      name="PrimaryFee"
                      placeholder="Primary Fee"
                      fullWidth
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ width: '100%', marginBottom: '12px' }}
            >
              <Grid item xs={20} tabletS={16}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Grid item xs={5} tabletS={2}>
                    <InputLabel>Type</InputLabel>
                    <SelectMenu
                      options={FeesType}
                      value={FeesType.find(
                        (option) =>
                          option.value === formikBag.values.SecondaryFeeType
                      )}
                      onChange={(option) =>
                        formikBag.setFieldValue(
                          'SecondaryFeeType',
                          option?.value
                        )
                      }
                      isRounded
                    />
                    {formikBag.errors.SecondaryFeeType &&
                      formikBag.touched.SecondaryFeeType && (
                        <FormHelperText error>
                          {formikBag.errors.SecondaryFeeType}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={7} tabletS={10}>
                    <TextFieldFormik
                      label="Secondary Fee"
                      type="number"
                      name="SecondaryFee"
                      placeholder="Secondary Fee"
                      fullWidth
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ width: '100%', marginBottom: '12px' }}
            >
              <Grid item xs={20} tabletS={16}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Grid item xs={6} tabletS={2}>
                    <InputLabel>Type</InputLabel>
                    <SelectMenu
                      options={FeesType}
                      value={FeesType.find(
                        (option) =>
                          option.value === formikBag.values.MonthlyFeeType
                      )}
                      onChange={(option) =>
                        formikBag.setFieldValue('MonthlyFeeType', option?.value)
                      }
                      isRounded
                    />
                    {formikBag.errors.MonthlyFeeType &&
                      formikBag.touched.MonthlyFeeType && (
                        <FormHelperText error>
                          {formikBag.errors.MonthlyFeeType}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={14} tabletS={10}>
                    <TextFieldFormik
                      label="Monthly Fee"
                      type="number"
                      name="MonthlyFee"
                      placeholder="Monthly Fee"
                      fullWidth
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: '1rem' }}>
              <Button
                component={LoadingButton}
                loading={showLoading}
                variant="contained"
                type="submit"
                size="small"
                sx={{ maxWidth: 100 }}
              >
                {!showLoading && `Submit`}
              </Button>
            </Box>
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  );
}

export default UpdateFee;
