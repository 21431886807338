import {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ThemeContext } from './context/ThemeContext';
import './css/calendar.css';
import './css/customize.css';
import './css/dropdown.css';
import './css/invoicehistory.css';
import './css/style.css';
/// Components
import Index from './jsx/index';

// action
import { checkAutoLogin, useTokenRegenMutation } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import DotsLoader from 'jsx/components/Loader/DotsLoader';
import Profile from './jsx/pages/Profile/ProfileUpdate';
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import { logout } from 'store/actions/AuthActions';
import { useIdleTimer } from 'react-idle-timer';
import PersonalDocument from 'jsx/pages/Profile/PersonalDocument';

const sumsub = lazy(() => import('./jsx/pages/Profile/personalverification'));
const UBOregister = lazy(() => import('./jsx/pages/Profile/UBOinformation'));
const Businessverification = lazy(() =>
  import('./jsx/pages/Profile/businessdocument')
);
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));
const ForgotPassword = lazy(() =>
  import('./jsx/pages/ForgetPassword/ForgetPassword')
);

const SignUp = lazy(() => import('./jsx/pages/SignUp/index'));
const EmailRegister = lazy(() =>
  import('./jsx/pages/EmailRegister/EmailRegister')
);
const EmailVerify = lazy(() => import('./jsx/pages/EmailVerify/EmailVerify'));
const OtpVerify = lazy(() => import('./jsx/pages/Admin/OtpVerify'));
const AccountVerify = lazy(() =>
  import('./jsx/pages/AccountVerify/AccountVerify')
);
const AccountDetails = lazy(() =>
  import('./jsx/pages/AccountDetails/AccountDetails')
);
const CreatePassword = lazy(() =>
  import('./jsx/pages/CreatePassword/CreatePassword')
);
const AccountTopupUrl = lazy(() =>
  import('./jsx/pages/Accounts/AccountTopup/AccountTopupUrl')
);
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/Login/Login')), 500);
  });
});
function App(props) {
  const { isAuthenticated: isUserAuthenticated } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const authTimer = useRef();
  const [checkingAuth, setCheckingAuth] = useState(true);

  const { user, isUserLoading } = useContext(ThemeContext);
  const { mutateAsync: regenToken } = useTokenRegenMutation();

  const onLogoutUser = (isTimerLogout) => {
    if (!user) return;

    if (isTimerLogout) {
      swal({
        allowOutsideClick: false,
        title: 'You have been logged out!',
        text: 'You have been inactive for 10 minutes!',
      });
    }
    dispatch(logout(props.history));
  };

  // Logout user after 10 mins of inactivity
  const timer = useIdleTimer({
    timeout: 10 * 60 * 1000,
    promptBeforeIdle: 0.5 * 60 * 1000,
    debounce: 500,
    onIdle: () => onLogoutUser(true),
    onPrompt: onPrompt,
  });

  function onPrompt() {
    if (!!user) {
      swal({
        allowOutsideClick: false,
        timer: 0.5 * 60 * 1000,
        title: 'You will be logged out!',
        text: 'You have been inactive for 10 minutes. Click button below to stay logged in!',
        buttons: {
          stay: {
            text: 'Stay logged in!',
            value: 'stay',
            className: 'btn btn-primary',
          },
          logout: {
            text: 'Logout!',
            value: 'logout',
            className: 'btn btn-outline-primary',
          },
        },
      }).then((value) => {
        if (value === 'stay') {
          timer.reset();
        } else if (value === 'logout') {
          onLogoutUser();
        }
      });
    } else {
      swal.close();
    }
  }

  const initAuth = useCallback(async () => {
    const { isExpired, logout: logoutUser } = checkAutoLogin(
      dispatch,
      props.history
    );

    if (logoutUser) {
      onLogoutUser();
    }

    if (isExpired) {
      if (!state.auth.refreshToken) {
        onLogoutUser();
      }

      try {
        await regenToken(state.auth.refreshToken);
      } catch (error) {
        console.log(error);
      }
    }

    setImmediate(() => {
      setCheckingAuth(false);
    });
  }, [dispatch, props.history, state.auth]);

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    if (user) {
      authTimer.current = setInterval(() => {
        initAuth();
      }, 60 * 1000);
    }

    return () => authTimer.current && clearInterval(authTimer.current);
  }, [user, initAuth]);

  const routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/admin-login" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/email-register" component={EmailRegister} />
      <Route path="/email-verify/:token" component={EmailVerify} />
      <Route path="/admin-otp/verify" component={OtpVerify} />
      <Route path="/account-details/:token" component={AccountDetails} />
      <Route path="/account-verify/:token" component={AccountVerify} />
      <Route path="/create-password/:token" component={CreatePassword} />

      <Route path="/forgetpassword" component={ForgotPassword} />
      <Route path="/resetpassword/:token" component={ResetPassword} />
      <Redirect to="/login" />
    </Switch>
  );

  if (checkingAuth || isUserLoading) {
    return <DotsLoader />;
  }

  if (props.history.location.pathname === '/topup') {
    return (
      <Suspense fallback={<DotsLoader />}>
        <Route path="/topup" component={AccountTopupUrl} />
      </Suspense>
    );
  }

  if (isUserAuthenticated) {
    return (
      <Suspense fallback={<DotsLoader />}>
        {(() => {
          if (
            user?.kyc.status === 'approved' &&
            !user?.id_verified &&
            (user.accountType === 'personal' || user.accountType === 'business')
          ) {
            return (
              <Switch>
                <Route
                  exact
                  path={`/profile-verification`}
                  component={sumsub}
                />
                <Redirect to="/profile-verification" />
              </Switch>
            );
          } else if (user?.kyc.status === 'initiable') {
            return (
              <Switch>
                <Route
                  exact
                  path={`/profile-verification`}
                  component={sumsub}
                />
                {user.accountType === 'personal' && (
                  <Route
                    exact
                    path={`/personal-verification`}
                    component={PersonalDocument}
                  />
                )}
                {user.accountType === 'business' && (
                  <Route
                    exact
                    path={`/business-verification`}
                    component={Businessverification}
                  />
                )}
                <Redirect to="/profile-verification" />
              </Switch>
            );
          } else if (user?.kyc.status === 'approved') {
            return <Index />;
          } else if ((user?.kyc.status === 'pending')) {
            return (
              <Switch>
                <Route exact path={`/profile-update`} component={Profile} />
                <Route
                  exact
                  path={`/company-structure`}
                  component={UBOregister}
                />
                <Redirect to="/profile-update" />
              </Switch>
            );
          }
          <Redirect to="/login"/>
        })()}
      </Suspense>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={<DotsLoader />}>{routes}</Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
