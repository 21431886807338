import React, { useEffect, useState } from 'react';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Stack,
  CardActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { updatetransactionlimit } from '../../../../services/User';
import { useHistory } from 'react-router-dom';

const UpdateTransactionLimit = ({
  documents,
  selectedLedger,
  Alldataavailable,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tabletS'));
  const transaction_limit = updatetransactionlimit();
  const history = useHistory();

  const getInitialTransaction = (ledger) => {
    return {
      iban: ledger?.iban || '',
      ledger_id: ledger?.ledger_id || '',
      transaction_limit: ledger?.ledger_meta?.ledger?.transaction_limit || 0,
      daily_limit: ledger?.ledger_meta?.ledger?.daily_limit || 0,
      account_name: ledger?.ledger_meta?.ledger?.account_name || 0,
      monthly_limit: ledger?.ledger_meta?.ledger?.monthly_limit || 0,
      dd_monthly_limit: ledger?.ledger_meta?.ledger?.dd_monthly_limit || 0,
      dd_transaction_limit:
        ledger?.ledger_meta?.ledger?.dd_transaction_limit || 0,
      monthly_limit_used:
        ledger?.ledger_meta?.ledger?.dd_monthly_limit_used || 0,
      daily_limit_used: ledger?.ledger_meta?.ledger?.daily_limit_used || 0,
    };
  };
  const ledgerInitialValues = getInitialTransaction(selectedLedger);
  const ledgerFormik = useFormik({
    ledgerInitialValues,
    onSubmit: (values) => {
      handleUpdateLedger(values);
    },
  });

  useEffect(() => {
    const updatedLedgerValues = getInitialTransaction(selectedLedger);
    ledgerFormik.setValues(updatedLedgerValues);
  }, [documents, selectedLedger]);

  const business = () => {
    const isLargeScreen = window.innerWidth > 1200;
    return isLargeScreen ? 4 : 6;
  };

  const handleUpdateLedger = async (formData) => {
    setIsSubmitting(true);
    let data = {
      id: formData?.ledger_id,
      account_name: formData?.account_name,
      transaction_limit: formData?.transaction_limit,
      monthly_limit: formData?.monthly_limit,
      dd_transaction_limit: formData?.dd_transaction_limit,
      dd_monthly_limit: formData?.dd_monthly_limit,
      daily_limit: formData?.daily_limit,
    };
    try {
      const res = await transaction_limit.mutateAsync(data);
      setIsSubmitting(false);
      swal({
        title: 'Success',
        icon: 'success',
        text: 'Transaction Limit Updated Successfully',
        button: true,
      }).then((res) => history.push('/users'));
    } catch (error) {
      setIsSubmitting(false);
      swal({
        title: 'Error',
        icon: 'error',
        text: error?.response?.data?.message,
        button: true,
      });
    }
  };

  return (
    <>
      <Card
        sx={{
          mx: 4,
          py: 5,
          boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
          borderRadius: 4,
        }}
      >
        <FormikProvider value={ledgerFormik}>
          <Form onSubmit={ledgerFormik.handleSubmit}>
            <CardContent sx={{ maxWidth: '80%', margin: 'auto' }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                fontWeight="medium"
                mx="auto"
                mb={6}
                color="var(--primary)"
                sx={{ maxWidth: '60%' }}
              >
                Update Transaction Limit
              </Typography>

              <Box sx={{ maxWidth: isMobile ? '80%' : '100%', margin: 'auto' }}>
                <Grid container spacing={2}>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Ledger ID"
                        type="text"
                        name="ledger_id"
                        placeholder="Ledger Id"
                        fullWidth
                        disabled
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Account Name"
                        type="text"
                        name="account_name"
                        placeholder="Account Name"
                        fullWidth
                        disabled
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="IBAN"
                        type="text"
                        name="iban"
                        placeholder="IBAN"
                        fullWidth
                        disabled
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Daily limit"
                        type="text"
                        name="daily_limit"
                        placeholder="Amount in cent"
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Daily Limit Used"
                        type="text"
                        name="daily_limit_used"
                        placeholder="Amount in cent"
                        fullWidth
                        disabled
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Transaction Limit"
                        type="text"
                        name="transaction_limit"
                        placeholder="Amount in cent"
                        fullWidth
                      />
                    </Stack>
                  </Grid>

                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Monthly Limit"
                        type="text"
                        name="monthly_limit"
                        placeholder="Monthly Limit in cent"
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tabletS={business()} width="100%">
                    <Stack>
                      <TextFieldFormik
                        label="Monthly Limit Used"
                        type="text"
                        name="monthly_limit_used"
                        placeholder="Amount in cent"
                        fullWidth
                        disabled
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>

            <CardActions
              sx={{
                maxWidth: '80%',
                margin: 'auto',
                pr: 2,
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ maxWidth: 150 }}
                onClick={() => Alldataavailable('updateLimit')}
              >
                Prev
              </Button>
              <Button
                variant="contained"
                component={LoadingButton}
                loading={isSubmitting}
                size="small"
                sx={{ maxWidth: 150 }}
                type="submit"
              >
                Save
              </Button>
            </CardActions>
          </Form>
        </FormikProvider>
      </Card>
      ;
    </>
  );
};

export default UpdateTransactionLimit;
