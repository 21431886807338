import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from './Api';

const { TirnuClient } = require('./Axios');

const checkIsEmployee = () => {
  return TirnuClient.get(API.v1.employeeMgmt.checkIsEmployee);
};
const useCheckIsEmployeeQuery = () => {
  // return useQuery(API.v1.employeeMgmt.checkIsEmployee, checkIsEmployee, {
  //   select: ({ data }) => data?.data,
  // });
};

const scanReceipt = (receipt) => {
  return TirnuClient.post(API.v1.employeeMgmt.receipt, receipt);
};
const useScanReceiptMutation = () => {
  return useMutation(scanReceipt, {
    onError: () => {
      // swal({
      //   title: 'Failed!',
      //   text: `Failed to upload receipt!`,
      //   buttons: 'Close',
      // });
    },
  });
};

const listEmployee = () => {
  return TirnuClient.get(API.v1.employeeMgmt.listEmployee);
};
const useListEmployeeQuery = () => {
  return useQuery(API.v1.employeeMgmt.listEmployee, listEmployee, {
    select: ({ data }) => data?.data,
    onError: () => {
      swal({
        title: 'Failed!',
        text: `Failed to add employee!`,
        buttons: 'Close',
      });
    },
  });
};

const addEmployee = (employeeInfo) => {
  return TirnuClient.post(API.v1.employeeMgmt.addEmployee, employeeInfo);
};
const useAddEmployeeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries([API.v1.employeeMgmt.listEmployee]);
    },
    onError: () => {
      swal({
        title: 'Failed!',
        text: `Failed to add employee!`,
        buttons: 'Close',
      });
    },
  });
};

const getPayerReimbursementInfo = () => {
  return TirnuClient.get(API.v1.employeeMgmt.reimbursement.total);
};
const useGetPayerReimbursementInfo = () => {
  return useQuery(
    API.v1.employeeMgmt.reimbursement.payee,
    getPayerReimbursementInfo,
    {
      select: ({ data }) => data?.data,
    }
  );
};

const listPayeeReimbursement = () => {
  return TirnuClient.get(API.v1.employeeMgmt.reimbursement.payee);
};
const useListPayeeReimbursement = () => {
  return useQuery(
    API.v1.employeeMgmt.reimbursement.payee,
    listPayeeReimbursement,
    {
      select: ({ data }) => data?.data,
      onError: () => {
        swal({
          title: 'Failed!',
          text: `Failed to list reimbursements!`,
          buttons: 'Close',
        });
      },
    }
  );
};

const listPayerReimbursement = () => {
  return TirnuClient.get(API.v1.employeeMgmt.reimbursement.payer);
};
const useListPayerReimbursement = () => {
  return useQuery(
    API.v1.employeeMgmt.reimbursement.payer,
    listPayerReimbursement,
    {
      select: ({ data }) => data?.data,
      onError: () => {
        swal({
          title: 'Failed!',
          text: `Failed to list reimbursements!`,
          buttons: 'Close',
        });
      },
    }
  );
};

const addReimbursement = (receiptInfo) => {
  return TirnuClient.post(API.v1.employeeMgmt.reimbursement.add, receiptInfo);
};
const useAddReimbursementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addReimbursement, {
    onSuccess: () => {
      queryClient.invalidateQueries([API.v1.employeeMgmt.reimbursement.payee]);
    },
    onError: () => {
      swal({
        title: 'Failed!',
        text: `Failed to add reimbursement!`,
        buttons: 'Close',
      });
    },
  });
};

const rejectReimbursement = ({ reimbursementId, remarks }) => {
  return TirnuClient.delete(API.v1.employeeMgmt.reimbursement.rejectByPayer, {
    data: {
      reimbursementId,
      remarks,
    },
  });
};
const useRejectReimbursementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(rejectReimbursement, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        API.v1.employeeMgmt.reimbursement.payee,
        API.v1.employeeMgmt.reimbursement.payer,
      ]);
    },
    onError: () => {
      swal({
        title: 'Failed!',
        text: `Failed to reject reimbursement!`,
        buttons: 'Close',
      });
    },
  });
};

const approveReimbursement = (data) => {
  return TirnuClient.post(API.v1.employeeMgmt.reimbursement.pay, {
    data,
  });
};
const useApproveReimbursementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(approveReimbursement, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        API.v1.employeeMgmt.reimbursement.payee,
        API.v1.employeeMgmt.reimbursement.payer,
      ]);
    },
    onError: () => {
      swal({
        title: 'Failed!',
        text: `Failed to pay reimbursement!`,
        buttons: 'Close',
      });
    },
  });
};

export {
  useCheckIsEmployeeQuery,
  useScanReceiptMutation,
  useListEmployeeQuery,
  useAddEmployeeMutation,
  useGetPayerReimbursementInfo,
  useListPayeeReimbursement,
  useListPayerReimbursement,
  useAddReimbursementMutation,
  useRejectReimbursementMutation,
  useApproveReimbursementMutation,
};
