import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import {
  useFetchCompanyLegalForm,
  useFetchCompanySize,
  useFetchRiskLevel,
} from '../../../../services/User';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  useMediaQuery,
  Stack,
  CardActions,
  FormHelperText,
} from '@mui/material';
import { InputLabel } from '../../../components/TextFieldFormik/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import SelectMenu from 'jsx/components/UI/Select';
import { updatebusinessfromadmin, updatepersonalfromadmin } from '../../../../services/User';

const UpdateUser = ({documents,row,theme,Alldataavailable}) => {

  const { data: riskLevel } = useFetchRiskLevel();
  const { data: companySize } = useFetchCompanySize();
  const { data: companyLegalForm } = useFetchCompanyLegalForm();
  const isMobile = useMediaQuery(theme.breakpoints.down('tabletS'));
  const [selectOptions, setSelectOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const updatebusiness = updatebusinessfromadmin();
  const updatepersonal = updatepersonalfromadmin();

  const getInitialValues = (accountType) => {
    if (accountType === 'business') {
      return {
        name: documents?.company_details?.name || '',
        email: row?.email || '',
        planned_income: documents?.company_details?.planned_income || '',
        planned_expenditure:
          documents?.company_details?.planned_expenditure || '',
        company_size_id: documents?.company_details?.company_size_id || '',
        legal_form_id: documents?.company_details?.legal_form_id || '',
        risk_level: documents?.company_details?.risk_level || '',
      };
    } else if (accountType === 'personal') {
      return {
        place_of_birth: documents?.personal_details?.place_of_birth || '',
        email: documents?.personal_details?.email || '',
        national_id: documents?.personal_details?.national_id || '',
        risk_level: documents?.personal_details?.risk_level || '',
        address_postal_code:
          documents?.personal_details?.address?.address_postal_code || '',
      };
    }
    return {};
  };

  const initialValues = getInitialValues(row?.accountType);

  const fromIkBag = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleUpdateUser(values, row._id);
    },
  });

  useEffect(() => {
    const updatedValues = getInitialValues(row?.accountType);
    fromIkBag.setValues(updatedValues);
  }, [documents, row?.accountType]);

  useEffect(() => {
    if (selectOptions) {
      setSelectOptions({
        riskLevel: riskLevel?.result?.map((obj) => {
          return {
            label: obj.risk_level,
            value: obj.id,
          };
        }),
        companySize: companySize?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
        companyLegalForm: companyLegalForm?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
      });
    }
  }, [riskLevel, companySize, companyLegalForm]);

  const handleUpdateUser = async (formData, userId) => {
    setIsSubmitting(true);
    try {
      let data = {
        userId: userId,
      };
      let res;
      if (row?.accountType === 'business') {
        if (formData) {
          let companyDetails = {
            name: formData.name,
            email: formData.email,
            planned_income: formData.planned_income,
            planned_expenditure: formData.planned_expenditure,
            company_size_id: formData.company_size_id,
            legal_form_id: formData.legal_form_id,
            risk_level: formData.risk_level,
          };
          companyDetails = Object.fromEntries(
            Object.entries(companyDetails).filter(
              ([_, value]) => value !== undefined && value !== ''
            )
          );
          if (Object.keys(companyDetails).length > 0) {
            data.company_details = companyDetails;
          }
        }
        res = updatebusiness.mutateAsync(data);

      } else if (row?.accountType === 'personal') {
        if (formData) {
          let personalDetails = {
            place_of_birth: formData.place_of_birth,
            email: formData.email,
            national_id: formData.national_id,
            risk_level: formData.risk_level,
            address_postal_code: formData.address_postal_code,
          };
          personalDetails = Object.fromEntries(
            Object.entries(personalDetails).filter(
              ([_, value]) => value !== undefined && value !== ''
            )
          );
          if (Object.keys(personalDetails).length > 0) {
            data.user_details = personalDetails;
          }
        }
        res = updatepersonal.mutateAsync(data);
      }

      swal({
        title: 'User updated Successfuly',
        icon: 'success',
        text: `${res.data.message}`,
        button: true,
      });

      history.push('/users');
    } catch (err) {
      swal({
        title: 'User updation Failed',
        icon: 'error',
        text: err.toString(),
        button: true,
      });
      setIsSubmitting(false);
    }
  };

  const business = () => {
    const isLargeScreen = window.innerWidth > 1200;
    return isLargeScreen ? 4 : 6;
  };

    return (
        <Card
            sx={{
              mx: 4,
              py: 5,
              boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
              borderRadius: 4,
            }}
          >
            <FormikProvider value={fromIkBag}>
              <Form onSubmit={fromIkBag.handleSubmit}>
                <CardContent
                  sx={{
                    maxWidth: '80%',
                    margin: 'auto',
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    align="center"
                    fontWeight="medium"
                    mx="auto"
                    mb={6}
                    color="var(--primary)"
                    sx={{ maxWidth: '60%' }}
                  >
                    Update Information
                  </Typography>

                  <Box
                    sx={{
                      maxWidth: isMobile ? '80%' : '100%',
                      margin: 'auto',
                    }}
                  >
                    <Grid container spacing={2}>
                      {row.accountType === 'business' && (
                        <>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Company Name"
                                type="text"
                                name="name"
                                placeholder="Eg:Apple"
                                fullWidth
                                disabled
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Email"
                                type="text"
                                name="email"
                                placeholder="Email"
                                fullWidth
                                disabled
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Legal Form</InputLabel>
                              <SelectMenu
                                options={selectOptions?.companyLegalForm}
                                value={selectOptions?.companyLegalForm?.find(
                                  (language) =>
                                    language.value ===
                                    fromIkBag.values.legal_form_id
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'legal_form_id',
                                    option?.value
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.legal_form_id &&
                                !!fromIkBag.touched.legal_form_id && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.legal_form_id}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Company Size</InputLabel>
                              <SelectMenu
                                options={selectOptions?.companySize}
                                value={selectOptions?.companySize?.find(
                                  (language) =>
                                    language.value ===
                                    fromIkBag.values.company_size_id
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'company_size_id',
                                    option?.value
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.company_size_id &&
                                !!fromIkBag.touched.company_size_id && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.company_size_id}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Risk level</InputLabel>
                              <SelectMenu
                                options={selectOptions?.riskLevel}
                                value={selectOptions?.riskLevel?.find(
                                  (language) =>
                                    language.label ===
                                    fromIkBag.values.risk_level
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'risk_level',
                                    option?.label
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.risk_level &&
                                !!fromIkBag.touched.risk_level && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.risk_level}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="PLANNED INCOME"
                                type="text"
                                name="planned_income"
                                placeholder="Planned Income"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="PLANNED EXPENDITURE"
                                type="text"
                                name="planned_expenditure"
                                placeholder="Planned Expenditure"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                        </>
                      )}
                      {row.accountType === 'personal' && (
                        <>
                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Place of Birth"
                                type="text"
                                name="place_of_birth"
                                placeholder="Place of Birth"
                                fullWidth
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Email"
                                type="text"
                                name="email"
                                placeholder="Email"
                                fullWidth
                                disabled
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="National ID"
                                type="text"
                                name="national_id"
                                placeholder="National ID"
                                fullWidth
                              />
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <InputLabel>Risk level</InputLabel>
                              <SelectMenu
                                options={selectOptions?.riskLevel}
                                value={selectOptions?.riskLevel?.find(
                                  (language) =>
                                    language.label ===
                                    fromIkBag.values.risk_level
                                )}
                                onChange={(option) =>
                                  fromIkBag.setFieldValue(
                                    'risk_level',
                                    option?.label
                                  )
                                }
                                isRounded
                              />
                              {!!fromIkBag.errors.risk_level &&
                                !!fromIkBag.touched.risk_level && (
                                  <FormHelperText
                                    sx={{ color: palette.error.main }}
                                  >
                                    {fromIkBag.errors.risk_level}
                                  </FormHelperText>
                                )}
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            mobile={12}
                            tabletS={business()}
                            width="100%"
                          >
                            <Stack>
                              <TextFieldFormik
                                label="Postal Code"
                                type="text"
                                name="address_postal_code"
                                placeholder="Eg:1050"
                                fullWidth
                              />
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </CardContent>

                <CardActions
                  sx={{
                    maxWidth: '80%',
                    margin: 'auto',
                    pr: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ maxWidth: 150 }}
                    onClick={() => Alldataavailable('')}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="contained"
                    component={LoadingButton}
                    loading={isSubmitting}
                    size="small"
                    sx={{ maxWidth: 150 }}
                    type="submit"
                  >
                    Save
                  </Button>
                </CardActions>
              </Form>
            </FormikProvider>
          </Card>
    )
}

export default UpdateUser;