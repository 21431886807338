import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../images/favicon.png';
import logoText from '../../../images/DMONEIII.png';

/// React router dom
/// images
const NavHader = (props) => {
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo" onClick={() => props.onClick()}>
        <img className="logo-abbr" src={logo} width={40} alt="favicon" />
        <img className="logo-compact" src={logo} width={40} alt="favicon" />
        <img
          className="brand-title"
          src={logoText}
          width={230}
          alt="text-logo"
        />
      </Link>

      <div className="nav-control">
        <div className={'hamburger'}>
          <span className="line" />
          <span className="line" />
          <span className="line" />
        </div>
      </div>
    </div>
  );
};

export default NavHader;
