import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  EEACountries,
  ISOCountry,
  CountriesNationality,
} from 'assets/ISO_country';
import { useFormikContext } from 'formik';
import PhoneInputFormik from 'jsx/components/PhoneInput/PhoneInputFormik';
import { InputLabel } from 'jsx/components/TextFieldFormik/styles';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import SelectMenu from 'jsx/components/UI/Select';
import { useFetchCountries } from '../../../services/User';
import { useEffect, useState } from 'react';

function ProfileUpdateForm({
  isBusinessProfile,
  isSavedOnce,
  isApproved,
  user,
}) {
  const formik = useFormikContext();
  const { palette } = useTheme();
  const { data: countries } = useFetchCountries();

  const [ISOCountryCodes, setISOCountryCodes] = useState([]);

  useEffect(() => {
    setISOCountryCodes(
      countries?.result?.map((code) => ({
        label: code.name,
        value: code.id,
      }))
    );
  }, [countries]);

  const isLargeScreen = window.innerWidth > 1200;

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="EMAIL"
            type="text"
            name="email"
            placeholder="Email"
            fullWidth
            disabled
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6}>
        <Stack>
          <PhoneInputFormik name="phone_number" label="PHONE NUMBER" disabled />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="FIRST NAME"
            type="text"
            name="first_name"
            placeholder="First name"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="LAST NAME"
            type="text"
            name="last_name"
            placeholder="Last name"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="NATIONAL ID NO"
            type="text"
            name="national_id"
            placeholder="National ID No"
            fullWidth
            disabled={(isSavedOnce || isApproved) && user.national_id}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="BIRTH PLACE"
            type="text"
            name="place_of_birth"
            placeholder="Birth Place"
            fullWidth
            disabled={(isSavedOnce || isApproved) && user.place_of_birth}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="BIRTHDATE"
            type="date"
            name="birthday"
            placeholder="Birthdate"
            max={new Date().toISOString().split('T')[0]}
            fullWidth
            disabled={(isSavedOnce || isApproved) && user.date_of_birth}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <InputLabel>GENDER</InputLabel>
          <SelectMenu
            options={genderOptions}
            value={genderOptions.find(
              (option) => option.value === formik.values.gender
            )}
            onChange={(option) => formik.setFieldValue('gender', option?.value)}
            isRounded
            isDisabled={(isSavedOnce || isApproved) && user?.gender}
          />
          {!!formik.errors.gender && !!formik.touched.gender && (
            <FormHelperText sx={{ color: palette.error.main }}>
              {formik.errors.gender}
            </FormHelperText>
          )}
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <InputLabel>COUNTRY</InputLabel>
          <SelectMenu
            options={ISOCountryCodes}
            value={ISOCountryCodes?.find(
              (country) => country.value == formik.values.address_country
            )}
            onChange={(option) =>
              formik.setFieldValue('address_country', option?.value.toString())
            }
            isRounded
            isDisabled={
              (isSavedOnce || isApproved) && user?.address?.address_iso_country
            }
          />
          {!!formik.errors.address_country &&
            !!formik.touched.address_country && (
              <FormHelperText sx={{ color: palette.error.main }}>
                {formik.errors.address_country}
              </FormHelperText>
            )}
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="CITY"
            type="text"
            name="address_city"
            placeholder="City"
            fullWidth
            disabled={
              (isSavedOnce || isApproved) && user?.address?.address_city
            }
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="POSTAL/ZIP CODE"
            type="text"
            name="address_postal_code"
            placeholder="Postal/Zip Code"
            fullWidth
            disabled={
              (isSavedOnce || isApproved) && user?.address?.address_postal_code
            }
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="STREET"
            type="text"
            name="address_street"
            placeholder="Street"
            fullWidth
            disabled={
              (isSavedOnce || isApproved) && user?.address?.address_street
            }
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <InputLabel>NATIONALITY</InputLabel>
          <SelectMenu
            options={ISOCountryCodes}
            value={ISOCountryCodes?.find(
              (country) => country.value == formik.values.nationality
            )}
            onChange={(option) =>
              formik.setFieldValue('nationality', option?.value.toString())
            }
            isRounded
            isDisabled={(isSavedOnce || isApproved) && user.nationality}
          />
          {!!formik.errors.nationality && !!formik.touched.nationality && (
            <FormHelperText sx={{ color: palette.error.main }}>
              {formik.errors.nationality}
            </FormHelperText>
          )}
        </Stack>
      </Grid>
      {!isBusinessProfile && (
        <Grid item mobile={12} tabletS={12}>
          <FormGroup
            error={
              !!formik.errors.accept_terms_condition &&
              !!formik.touched.accept_terms_condition
            }
          >
            <FormControlLabel
              sx={{ alignItems: 'flex-start', m: 0 }}
              control={
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue(
                      'accept_terms_condition',
                      e.target.checked
                    )
                  }
                  checked={!!formik.values.accept_terms_condition}
                />
              }
              label={
                <Stack direction="row" ml={1}>
                  <Typography>
                    I have read, understood and agree to Tirnu's{' '}
                    <Link
                      href="https://tirnu.com/terms-conditions/"
                      target="_blank"
                    >
                      terms and conditions
                    </Link>{' '}
                    and{' '}
                    <Link href="https://tirnu.com/privacy/" target="_blank">
                      privacy policy
                    </Link>
                    .
                  </Typography>
                </Stack>
              }
            />
            <FormHelperText sx={{ color: palette.error.main }}>
              {formik.errors.accept_terms_condition}
            </FormHelperText>
          </FormGroup>
        </Grid>
      )}
    </Grid>
  );
}

export default ProfileUpdateForm;
