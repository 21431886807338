import { ThemeContext } from 'context/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useCreateLedgerMutation } from 'services/Ledger';

import Button from '../../../components/UI/Button';

import {
  AccCreationModalBody,
  AccCreationModalText,
  AccCreationModalTitle,
} from '../Accounts.styles';
import accountList from '../Shared/account-list';
import AccountDropdown from './Dropdown';

export const accountCreationParams = '?account-modal-open=true';
export const modalBackDropColor = 'rgba(0, 145, 147, 0.5)';

function AccountCreation() {
  const { initUserDetails } = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();

  const { mutateAsync: createLedger, isLoading: isCreatingLedger } =
    useCreateLedgerMutation();

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [association, setAssociation] = useState('');

  const handleModalClose = () => {
    history.push({ location: location.pathname });
  };

  const handleClick = async () => {
    if (selectedAccountType) {
      const ledgerCreation = await createLedger({
        ledgerAssetType: selectedAccountType,
        association,
      });
      if (ledgerCreation.status === 201) {
        handleModalClose();
        // initUserDetails();
      }
    }
  };

  useEffect(() => {
    if (location.search && location.search.includes(accountCreationParams)) {
      setCreationModalOpen(true);
    } else {
      setCreationModalOpen(false);
    }
  }, [location.search]);

  return (
    <Modal
      show={creationModalOpen}
      onHide={handleModalClose}
      centered
      size="md"
      style={{
        backgroundColor: modalBackDropColor,
      }}
    >
      <AccCreationModalBody>
        <AccCreationModalTitle>Open a currency account</AccCreationModalTitle>
        <AccountDropdown
          handleSelect={(account) => setSelectedAccountType(account)}
          handleAssociation={(text) => setAssociation(text)}
        />
        <AccCreationModalText>
          You can open accounts in {accountList.length} currencies.
        </AccCreationModalText>
        <Button
          className="py-2 w-60"
          size="lg"
          arial-label="Confirm"
          onClick={() => handleClick()}
          isLoading={isCreatingLedger}
          loadingText="Creating Ledger..."
        >
          Confirm
        </Button>
      </AccCreationModalBody>
    </Modal>
  );
}

export default AccountCreation;
