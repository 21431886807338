import React, { useEffect, useState, useContext, useRef } from 'react';
import swal from 'sweetalert';
import {
  Box,
  Button,
  Tooltip,
  Alert,
  TableCell,
  CardActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TiEdit } from 'react-icons/ti';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArticleIcon from '@mui/icons-material/Article';
import Nav from '../../layouts/nav';
import { ThemeContext } from '../../../context/ThemeContext';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { store } from '../../../store/store';
import { useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import {
  MdBlockFlipped,
  MdDeleteForever,
  MdDone,
  MdPersonAddAlt,
} from 'react-icons/md';
import TableWrapperComponent from 'jsx/components/BasicTable/BasicTable';
import { TableRowInvestment } from 'jsx/components/BasicTable/styles';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { WindowScrollController } from '@fullcalendar/react';
import { ConstructionOutlined } from '@mui/icons-material';
import { IoDocumentText } from 'react-icons/io5';

const PersonalDocument = () => {
  const state = useSelector((state) => state.auth);
  const history = useHistory();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [documents, setDocuments] = useState({
    identificationDocumentfront: null,
    identificationDocumentback: null,
    ClientAgreement: null,
    utilityBill: null,
    sourceoffund: null,
  });
  const [errorMessages, setErrorMessages] = useState({});
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { activeEvent, setActiveEvent, windowWidth, user } =
    useContext(ThemeContext);
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onBoardingDocs, setOnBoardingDocs] = useState(true);

  useEffect(() => {
    if (user) {
      if (user.accountType === 'personal' || user?.id_verified) {
        history.push('/personal-verification');
      }
    }
  }, [user]);
  const headCellsDocuments = [
    {
      sortName: 'Document ',
      label: 'Document',
    },
    {
      sortName: 'status',
      label: 'Status',
    },
    {
      sortName: 'filename',
      label: 'Filename',
    },
    {
      sortName: 'Upload Document',
      label: 'Upload Document',
    },
  ];

  const showDocument = (document) => {
    if (!user?.documents) {
      const url = URL.createObjectURL(document);
      window.open(url, '_blank');
    } else {
      const url = `${process.env.REACT_APP_BACKEND_API}/documents/${document}/${state.auth.accessToken}`;
      window.open(url, '_blank');
    }
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (typeof fileName !== 'string') return '';
    if (fileName.length <= maxLength) return fileName;

    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.substring(
      0,
      fileName.length - extension.length - 1
    );
    return `${nameWithoutExtension.substring(0, maxLength)}...${extension}`;
  };

  const handleFileUpload = (documentType, file) => {
    if (file) {
      if (documentType === 'CRA_sheet' && !isExcelFile(file)) {
        setDocuments((prevDocuments) => ({
          ...prevDocuments,
          CRA_sheet: null,
        }));
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          CRA_sheet: 'Please upload a valid Excel file for CRA sheet.',
        }));
      } else {
        setDocuments((prevDocuments) => ({
          ...prevDocuments,
          [documentType]: file,
        }));
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          CRA_sheet: null,
        }));
      }
    }
  };

  const uploadDocument = () => {
    const newErrorMessages = {};
    const requiredDocuments = [
      'identificationDocumentfront',
      // 'CRA_sheet',
      // 'ClientAgreement',
      'purposeOfAccount',
      'identificationDocumentback',
      'sourceoffund',
      'utilityBill',
    ];

    requiredDocuments.forEach((doc) => {
      const documentEntries = documents?.[doc];
      const existingDocument = user?.documents?.find(
        (docItem) => docItem.documentType === doc
      );

      if (
        !documentEntries ||
        (Array.isArray(documentEntries) && documentEntries.length === 0)
      ) {
        if (
          !existingDocument ||
          (existingDocument?.documentStatus !== 'approved' &&
            existingDocument?.documentStatus !== 'pending')
        ) {
          newErrorMessages[doc] = `${doc} is required.`;
        }
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
      return;
    } else {
      setErrorMessages({});
    }

    setIsUserLoading(true);
    const formData = new FormData();
    let hasFiles = false;
    Object.keys(documents).forEach((docType) => {
      if (documents[docType]) {
        formData.append(docType, documents[docType]);
        hasFiles = true;
      }
    });

    if (!hasFiles) {
      setLoading(false);
      setErrorMessages({ form: 'No files to upload.' });
      return;
    }

    setLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/personal/documents`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setErrorMessages({});
        swal({
          title: 'Personal documents uploaded',
          icon: 'success',
          text: 'Personal documents uploaded successfully.',
          button: true,
        });
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: 'Personal documents upload failed',
          icon: 'error',
          text: error.response?.data?.message || 'An error occurred.',
          button: true,
        });
      });
  };

  const extractFileNameAfterHyphen = (path) => {
    const fileName = path?.split('/')?.pop();
    const parts = fileName?.split('-');
    return parts?.slice(1)?.join('-');
  };

  const handleNext = () => {
    setOnBoardingDocs(false);
  };
  const handlePrev = () => {
    setOnBoardingDocs(true);
  };

  return (
    <div id="main-wrapper" className="show">
      <Nav
        onClick={() => setActiveEvent(!activeEvent)}
        activeEvent={activeEvent}
        onClick2={() => {
          setActiveEvent(!activeEvent);
          toggleHeaderOnMobile();
        }}
        onClick3={() => setActiveEvent(true)}
      />
      <div className={`${activeEvent ? 'rightside-event' : ''} content-body`}>
        <div
          className={`${windowWidth > 2000 ? 'container' : 'container-fluid'}`}
          style={{ minHeight: window.screen.height - 60, paddingTop: '0px' }}
        >
          {onBoardingDocs ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <IoDocumentText size={35} style={{ color: '#009193' }} />
                <h5
                  style={{
                    fontSize: '25px',
                    margin: '0',
                    color: '#5a5a60',
                  }}
                >
                  Onboarding Documentation
                </h5>{' '}
              </div>
              <div className="py-4">
                <p className="p-0 m-0">
                  Please include all the documents required for onboarding and
                  use the "Upload Document" button to upload files.(max file
                  size: 21MB per document )
                </p>
                <p className="p-0 m-0 " style={{ color: '#ee3232' }}>
                  Note:
                  <span style={{ color: '#ee3232' }}>
                    Client ID is the document of the agreement held in between
                    clientId and banking agent
                  </span>
                  .
                </p>
              </div>{' '}
              <div style={{ borderRadius: '5px', backgroundColor: 'white' }}>
                <div style={{ padding: '16px' }}>
                  {' '}
                  {/* <h5 className="pb-1 mx-0 " style={{ fontSize: '20px' }}>
                    Documents
                  </h5> */}
                  <TableWrapperComponent
                    headerCells={headCellsDocuments}
                    sx={{ width: '100%' }}
                  >
                    {[
                      // { label: 'CRA sheet', key: 'CRA_sheet' },
                      // { label: 'Client ID', key: 'ClientAgreement' },
                      {
                        label: 'Purpose of the account',
                        key: 'purposeOfAccount',
                      },
                    ].map(({ label, key }, index) => (
                      <TableRowInvestment
                        sx={{
                          padding: '15px',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        className="text-capitalize"
                        key={index}
                      >
                        <TableCell sx={tableCellStyle}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginRight: '-15px',
                            }}
                          >
                            <Box
                              sx={{
                                paddingTop: '5px',
                                textTransform: 'capitalize',
                                color: '#8B9DB8',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              {label}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          {documents[key] ? (
                            <FileUploadIcon style={{ color: '#339193' }} />
                          ) : (
                            (() => {
                              const matchingDocument = user?.documents?.find(
                                (doc) => doc?.documentType === key
                              );

                              if (matchingDocument?.documentStatus) {
                                switch (matchingDocument.documentStatus) {
                                  case 'pending':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: 'goldenrod',
                                        }}
                                      >
                                        <div>
                                          <CircleIcon
                                            style={{ fontSize: 18 }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  case 'rejected':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: '#ee3232',
                                        }}
                                      >
                                        <div>
                                          <CancelIcon
                                            style={{ fontSize: 18 }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  case 'approved':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: 'green',
                                        }}
                                      >
                                        <div>
                                          <MdDone style={{ fontSize: 18 }} />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  default:
                                    return (
                                      <Box>
                                        {matchingDocument.documentStatus}
                                      </Box>
                                    );
                                }
                              } else {
                                return <Box>No file</Box>;
                              }
                            })()
                          )}
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          <Tooltip title={documents?.[key]?.name || ''}>
                            <Button
                              variant="text"
                              sx={{
                                color: documents[key]?.name
                                  ? '#339193'
                                  : '#8B9DB8',
                                mb: 1,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                              }}
                              onClick={() => {
                                try {
                                  if (documents?.[key] && !user?.documents) {
                                    showDocument(documents[key]);
                                  } else {
                                    const documentPath = user?.documents?.find(
                                      (doc) => doc?.documentType === key
                                    )?.documentPath;
                                    if (documentPath) {
                                      showDocument(documentPath);
                                    }
                                  }
                                } catch (error) {}
                              }}
                            >
                              {documents?.[key]?.name ? (
                                truncateFileName(documents[key].name)
                              ) : user?.documents?.find(
                                  (doc) => doc?.documentType === key
                                )?.documentPath ? (
                                <Box>
                                  {extractFileNameAfterHyphen(
                                    user?.documents?.find(
                                      (doc) => doc?.documentType === key
                                    )?.documentPath
                                  )}
                                </Box>
                              ) : (
                                <Box>No file</Box>
                              )}
                            </Button>
                          </Tooltip>
                        </TableCell>

                        <TableCell sx={tableCellStyle}>
                          <Button
                            component="label"
                            startIcon={<ArticleIcon />}
                            sx={{
                              textTransform: 'none',
                              color: documents[key]?.name
                                ? '#339193'
                                : '#8B9DB8',
                              padding: '4px 8px',
                              fontSize: '0.875rem',
                              border: documents[key]?.name
                                ? '1px solid #339193'
                                : '1px solid #8B9DB8',
                              minHeight: '32px',
                            }}
                            disabled={['pending', 'approved'].includes(
                              user?.documents?.find(
                                (doc) => doc?.documentType === key
                              )?.documentStatus
                            )}
                          >
                            Upload Document
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleFileUpload(key, e.target.files[0])
                              }
                            />
                          </Button>
                        </TableCell>
                      </TableRowInvestment>
                    ))}
                  </TableWrapperComponent>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingRight: '100px',
                  marginTop: '50px',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <MdPersonAddAlt size={35} style={{ color: '#009193' }} />
                <h5
                  style={{
                    fontSize: '25px',
                    margin: '0',
                    color: '#5a5a60',
                  }}
                >
                  Personal Documentation
                </h5>{' '}
              </div>
              <div className="py-4">
                <p className="p-0 m-0">
                  Please use the "Upload Document" button to upload all the
                  required document.(max file size: 21MB per document )
                </p>
                <p className="p-0 m-0 " style={{ color: '#ee3232' }}>
                  Note:
                  <span style={{ color: '#ee3232' }}>
                    Utility Document should be recent(usually less than 3months
                    old)
                  </span>
                </p>
              </div>{' '}
              <div style={{ borderRadius: '5px', backgroundColor: 'white' }}>
                <div style={{ padding: '16px' }}>
                  {' '}
                  {/* <h5 className="pb-1 mx-0 " style={{ fontSize: '20px' }}>
                    Documents
                  </h5> */}
                  <TableWrapperComponent
                    headerCells={headCellsDocuments}
                    sx={{ width: '100%' }}
                  >
                    {[
                      {
                        label: 'Identification documents(Front)',
                        key: 'identificationDocumentfront',
                      },
                      {
                        label: 'Identification documents(Back)',
                        key: 'identificationDocumentback',
                      },

                      {
                        label: 'Utility document',
                        key: 'utilityBill',
                      },
                      {
                        label: 'Source of fund ',
                        key: 'sourceoffund',
                      },
                      //   { label: 'Risk assessment', key: 'riskAssessment' },
                    ].map(({ label, key }, index) => (
                      <TableRowInvestment
                        sx={{
                          padding: '15px',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        className="text-capitalize"
                        key={index}
                      >
                        <TableCell sx={tableCellStyle}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginRight: '-15px',
                            }}
                          >
                            <Box
                              sx={{
                                paddingTop: '5px',
                                textTransform: 'capitalize',
                                color: '#8B9DB8',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              {label}
                            </Box>
                          </Box>
                          {errorMessages[key] && (
                            <Alert severity="error" sx={{ marginTop: 1 }}>
                              {errorMessages[key]}
                            </Alert>
                          )}
                        </TableCell>

                        <TableCell sx={tableCellStyle}>
                          {documents[key] ? (
                            <FileUploadIcon style={{ color: '#339193' }} />
                          ) : (
                            (() => {
                              const matchingDocument = user?.documents?.find(
                                (doc) => doc?.documentType === key
                              );

                              if (matchingDocument?.documentStatus) {
                                switch (matchingDocument.documentStatus) {
                                  case 'pending':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: 'goldenrod',
                                        }}
                                      >
                                        <div>
                                          <CircleIcon
                                            style={{ fontSize: 18 }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  case 'rejected':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: '#ee3232',
                                        }}
                                      >
                                        <div>
                                          <CancelIcon
                                            style={{ fontSize: 18 }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  case 'approved':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: 'green',
                                        }}
                                      >
                                        <div>
                                          <MdDone style={{ fontSize: 18 }} />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  default:
                                    return (
                                      <Box>
                                        {matchingDocument.documentStatus}
                                      </Box>
                                    );
                                }
                              } else {
                                return <Box>No file</Box>;
                              }
                            })()
                          )}
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          <Tooltip title={documents?.[key]?.name || ''}>
                            <Button
                              variant="text"
                              sx={{
                                color: documents[key]?.name
                                  ? '#339193'
                                  : '#8B9DB8',
                                mb: 1,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                              }}
                              onClick={() => {
                                try {
                                  if (documents?.[key] && !user?.documents) {
                                    showDocument(documents[key]);
                                  } else {
                                    const documentPath = user?.documents?.find(
                                      (doc) => doc?.documentType === key
                                    )?.documentPath;
                                    if (documentPath) {
                                      showDocument(documentPath);
                                    }
                                  }
                                } catch (error) {
                                  // Handle error if needed
                                }
                              }}
                            >
                              {documents?.[key]?.name ? (
                                truncateFileName(documents[key].name)
                              ) : user?.documents?.find(
                                  (doc) => doc?.documentType === key
                                )?.documentPath ? (
                                <Box>
                                  {extractFileNameAfterHyphen(
                                    user?.documents?.find(
                                      (doc) => doc?.documentType === key
                                    )?.documentPath
                                  )}
                                </Box>
                              ) : (
                                <Box>No file</Box>
                              )}
                            </Button>
                          </Tooltip>
                        </TableCell>

                        <TableCell sx={tableCellStyle}>
                          <Button
                            component="label"
                            startIcon={<ArticleIcon />}
                            sx={{
                              textTransform: 'none',
                              color: documents[key]?.name
                                ? '#339193'
                                : '#8B9DB8',
                              padding: '4px 8px',
                              fontSize: '0.875rem',
                              border: documents[key]?.name
                                ? '1px solid #339193'
                                : '1px solid #8B9DB8',
                              minHeight: '32px',
                            }}
                            disabled={['pending', 'approved'].includes(
                              user?.documents?.find(
                                (doc) => doc?.documentType === key
                              )?.documentStatus
                            )}
                          >
                            Upload Document
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleFileUpload(key, e.target.files[0])
                              }
                            />
                          </Button>
                        </TableCell>
                      </TableRowInvestment>
                    ))}
                  </TableWrapperComponent>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingRight: '100px',
                  marginTop: '50px',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Button
                  component={LoadingButton}
                  loading={loading}
                  variant="contained"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={uploadDocument}
                >
                  {!loading && 'Submit'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const tableCellStyle = {
  minWidth: '100px',

  overflow: 'hidden',
  color: '#8B9DB8',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export default PersonalDocument;
