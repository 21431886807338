import { useDispatch, useSelector } from 'react-redux';
import { sendMoneyRecipientSelector } from 'store/selectors/SendMoneyRecipientSelector';
import { TransferVerificationModalName } from 'store/actions/ModalActions/ModalActionTypes';
import {
  useGetCurrencyOptions,
  useGetLedgersOptions,
  useGetLedgersOptionsById,
} from 'services/Ledger';
import { ModalOpenAction } from 'store/actions/ModalActions/ModalOpenAction';
import {
  SendMoneyInternationalAction,
  SendMoneyInternationalLoadingAction,
} from 'store/actions/SendMoneyInternationalActions/SendMoneyInternationalAction';
import { SendMoneyInternationalSelector } from 'store/selectors/SendMoneyInternationalSelector';
import {
  useFetchExchangeRate,
  useSendMoneyInternational,
} from 'services/SendMoney';
import { getDateNow } from 'utils/getDateNow';
import { internationalCountries } from 'jsx/pages/SendMoney/SendMoneyTab/International';
import { useTransferUsingBankAccountMutation } from 'services/Transfer';
import { usePlaidLink } from 'react-plaid-link';
import { useEffect, useState } from 'react';

// The usePlaidLink hook manages Plaid Link creation
// It does not return a destroy function;
// instead, on unmount it automatically destroys the Link instance
const config = {
  onSuccess: (token, metadata) => {
    console.log(token, metadata);
    window.location.reload();
  },
  onExit: console.log,
  onEvent: console.log,
  token: '',
  //required for OAuth; if not using OAuth, set to null or omit:
  // receivedRedirectUri: window.location.href,
};

export const useSendMoneyToInternationalState = () => {
  const dispatch = useDispatch();

  const { ledgersOptions } = useGetLedgersOptions();
  const { currencyOptions } = useGetCurrencyOptions();
  const { mutateAsync: fetchExchangeRate } = useFetchExchangeRate();
  const { mutateAsync: transferMoneyInternational } =
    useSendMoneyInternational();

  // Plaid token
  const { mutateAsync: getBankTransferToken, isLoading: isTokenLoading } =
    useTransferUsingBankAccountMutation();
  // Plaid UI
  const [plaidConfig, setPlaidConfig] = useState(config);
  const { open, ready } = usePlaidLink(plaidConfig);
  useEffect(() => ready && open(), [ready, open]);

  const selectedLedger = useSelector(sendMoneyRecipientSelector);
  const { sendInternationalData, isLoading } = useSelector(
    SendMoneyInternationalSelector
  );
  const {
    ledgersOptionsById: transferFrom,
    isFetching: isLoadingLedgerDetails,
  } = useGetLedgersOptionsById(sendInternationalData?.transferFrom);

  const getConversionAmount = ({ amount, transferToCountry }) =>
    fetchExchangeRate({ amount: Number(amount), transferToCountry });

  const sendMoneyInternationalValues = (values) => {
    dispatch(
      SendMoneyInternationalAction({
        transferFrom: values.transferFrom,
        transferToCountry: values.transferToCountry,
        transferToBank: values.transferToBank,
        transferToAccountName: values.transferToAccountName,
        transferTo: values.transferTo,
        transferToNumber: values.transferToNumber,
        transferAmount: values.transferAmount,
        transferFee: values.transferFee,
        transferConversionRate: values.transferConversionRate,
        transferConversionAmount: values.transferConversionAmount,
        currency: values.currency,
        description: values.description,
      })
    );
  };

  const handleInternationalTransfer = async (paymentType) => {
    try {
      const date = getDateNow();
      dispatch(SendMoneyInternationalLoadingAction(true));
      const transferCurrency = internationalCountries.find(
        (c) => c.value === sendInternationalData?.transferToCountry
      )?.currency;

      const requestInfo = {
        data: {
          ledger_to_id: '',
          ledger_from_id: sendInternationalData?.transferFrom,
          amount: sendInternationalData?.transferAmount,
          scheduledChecked: false,
          transaction_meta: {
            amount: sendInternationalData?.transferAmount,
            currency: sendInternationalData?.currency,
            reference: sendInternationalData?.description,
            date: date,
            receiver: {
              name: '',
            },
            receiver_account: {
              iban: '',
            },
            receiver_agent: {
              bic: '',
            },
            sender: {
              name: '',
            },
            sender_account: {
              iban: transferFrom?.iban,
            },
            sender_agent: {
              bic: transferFrom.bic_swift,
            },
          },
        },
        overseas_receiver: {
          transferToCountry: sendInternationalData?.transferToCountry,
          transferToBank: sendInternationalData?.transferToBank,
          transferToAccountName: sendInternationalData?.transferToAccountName,
          transferTo: sendInternationalData?.transferTo,
          transferToNumber: sendInternationalData?.transferToNumber,
          transferFee: sendInternationalData?.transferFee,
          transferConversionRate: Number(
            sendInternationalData?.transferConversionRate
              .replace(transferCurrency, '')
              .trim()
          ),
          transferConversionAmount: Number(
            sendInternationalData?.transferConversionAmount
              .replace(transferCurrency, '')
              .trim()
          ),
        },
      };

      if (paymentType === 'bank') {
        try {
          const transferToken = await getBankTransferToken(requestInfo);
          setPlaidConfig((config) => ({
            ...config,
            token: transferToken.data.data.link_token,
          }));
        } catch (error) {}
      } else {
        const response = await transferMoneyInternational(requestInfo);
        if (response.data?.method?.token) {
          dispatch(
            ModalOpenAction({
              modalName: TransferVerificationModalName,
              content: {
                fee: sendInternationalData?.transferFee,
                token: response.data.method.token,
                time: response.data?.method?.expires_at,
                isCallFromTransfer: false,
              },
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(SendMoneyInternationalLoadingAction(false));
    }
  };

  return {
    state: {
      store: {
        selectedLedger,
        sendInternationalData,
        transferAmount: sendInternationalData?.transferAmount,
        currency: sendInternationalData?.currency,
        transferFrom,
        transferTo: {
          transferToBank: sendInternationalData?.transferToBank,
          transferToAccountName: sendInternationalData?.transferToAccountName,
          transferTo: sendInternationalData?.transferTo,
          transferToNumber: sendInternationalData?.transferToNumber,
          transferAmount: sendInternationalData?.transferAmount,
          transferConversionAmount:
            sendInternationalData?.transferConversionAmount,
          currency: sendInternationalData?.currency,
          description: sendInternationalData?.description,
        },
        isLoadingTransfer: isLoading || isTokenLoading,
        ledgersOptions,
        currencyOptions,
        isLoadingLedgerDetails,
      },
      dispatchs: {
        sendMoneyInternationalValues: sendMoneyInternationalValues,
        handleInternationalTransfer: handleInternationalTransfer,
        getConversionAmount: getConversionAmount,
      },
    },
  };
};
