import * as yup from 'yup';
import * as MESSAGES from 'constants/constants';

export const internationalStep1Schema = (ledgers) =>
  yup.object().shape({
    // transferFrom: yup.string().required(MESSAGES.SELECT_REQUIRE_MESSAGE),
    transferToBank: yup.string().required(MESSAGES.SELECT_REQUIRE_MESSAGE),
    transferToAccountName: yup
      .string()
      .required(MESSAGES.SELECT_REQUIRE_MESSAGE),
    transferTo: yup
      .string()
      .when(['transferFrom'], (transferFrom, schema) => {
        return schema.notOneOf(
          [transferFrom],
          'Transfer accounts cannot be the same'
        );
      })
      .required(MESSAGES.SELECT_REQUIRE_MESSAGE),
    transferAmount: yup
      .number()
      .typeError(MESSAGES.TRANSFER_AMOUNT_FORMAT_MESSAGE)
      .positive(MESSAGES.TRANSFER_AMOUNT_MIN_MESSAGE)
      .required(MESSAGES.SELECT_REQUIRE_MESSAGE),
    // .test('against Balance', MESSAGES.LOW_BALANCE, (value, context) => {
    //   return (
    //     ledgers.find((obj) => obj.value === context.parent.transferFrom)
    //       ?.amount >= value
    //   );
    // }),
    currency: yup.string().required(MESSAGES.SELECT_REQUIRE_MESSAGE),
  });
