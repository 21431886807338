import axios from 'axios';
import { internationalCountries } from 'jsx/pages/SendMoney/SendMoneyTab/International';
import { txnType } from 'jsx/pages/Transactions/TransactionList/TransactionLabel';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { ThemeContext } from '../../../context/ThemeContext';
import TransactionInfo from 'jsx/pages/Transactions/TransactionList/TransactionInfo';

const NepalTransferInfo = ({ transferInfo }) => {
  if (transferInfo.transaction_type === txnType.TXN_INTERNATIONAL) {
    return (
      <Row>
        <Col xs={12}>
          <span>
            Name: {transferInfo.overseas_receiver?.transferToAccountName}
          </span>
        </Col>
        <Col xs={12}>
          <span>
            Bank: {transferInfo.overseas_receiver?.transferToBank?.name}
          </span>
        </Col>
        <Col xs={12}>
          <span>
            Account Number: {transferInfo.overseas_receiver?.transferTo}
          </span>
        </Col>
        <Col xs={12}>
          <span>
            Amount:{' '}
            {
              internationalCountries.find(
                (c) =>
                  c.value === transferInfo?.overseas_receiver?.transferToCountry
              ).currency
            }{' '}
            {
              transferInfo.overseas_receiver?.transferConversionAmount[
                '$numberDecimal'
              ]
            }
          </span>
        </Col>
      </Row>
    );
  }
  return null;
};

const TransfersInfoPopup = () => {
  const { state, InfoPopupHandler } = useContext(ThemeContext);
  const {
    transferPopupData,
    transferPopupForFees,
    Ledger: ledgerInfo,
    user,
  } = useContext(ThemeContext);
  const [cryptoTransData, setCryptoTransData] = useState();

  let Ledger = ledgerInfo;
  if (user?.accountType === 'agent_nepal') {
    Ledger = { iban: '' };
  }

  const transferInfo = transferPopupData?.[0] || {};

  useEffect(() => {
    if (
      transferPopupData.length > 0 &&
      transferInfo.transaction_meta.cryptoTransactionId
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API}/api/v1/user/${transferInfo.transaction_meta.cryptoTransactionId}/cryptoTransactionById`,
          {
            headers: {
              Authorization: `Bearer ${state.auth.accessToken}`,
              'x-refresh': `${state.auth.refreshToken}`,
            },
          }
        )
        .then((response) => {
          setCryptoTransData(response.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [transferPopupData]);

  return (
    <div>
      {transferPopupData.length > 0 ? (
        <TransactionInfo
          open={true}
          handleClose={InfoPopupHandler}
          txnInfo={transferInfo}
          isFees={transferPopupForFees}
          activeIBAN={Ledger.iban}
        />
      ) : (
        <div
          style={{
            height: '363px',
            boxShadow: '0 3.5px 17.5px 7px rgb(0 0 0 / 2%)',
            maxWidth: '589px',
            width: '100%',
            borderRadius: '15px',
            backgroundColor: 'white',
          }}
          className="d-flex mx-auto justify-content-center align-items-center"
        >
          <Spinner animation="border" className="text-primary" role="status">
            <span className="visually-hidden" />
          </Spinner>
        </div>
      )}
    </div>
  );
};
export default TransfersInfoPopup;
const TransfersInfoWrapper = styled.div`
  border-radius: 15px;
  box-shadow: 0 3.5px 17.5px 7px rgb(0 0 0 / 2%);
  background: var(--white);
  margin: 0 auto 35px auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
  width: 100%;
  min-height: 363px;
`;
const UserInfoWrapper = styled.div`
  background: linear-gradient(112.38deg, #47ac98 -12.05%, #88dacc 112.07%);
  padding: 20px 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 15px 0 0 15px;
  text-align: center;
  width: 100%;
  height: 100%;
`;
const UserImage = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;
const UserInfo = styled.div`
  & p {
    font-weight: 500;
    font-size: 18px;
  }

  & h1 {
    font-weight: 400;
    font-size: 36px;
    color: var(--white);
  }
`;
const UserDetails = styled.div`
  padding: 30px;
  border-radius: 0 15px 15px 0;
  width: 100%;
  height: 100%;
`;
const Title = styled.h4``;
const PriceInfo = styled.div`
  width: 100%;
  border-width: 1px 0 1px 0;
  border-style: solid;
  padding: 5px 0;
  border-color: #f4f7fe;
  margin-bottom: 20px;

  & > div {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      margin-bottom: 10px;
    }

    & span:first-of-type {
      font-weight: 300;
    }

    & span:last-of-type {
      font-weight: 600;
      color: black;
    }
  }
`;
const Details = styled.div`
  & ul {
    & li {
      margin-bottom: 15px;

      & p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        color: black;
        line-height: 16px;
      }

      & span {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
`;
const UserImagePlaceholder = styled.div`
  width: 91px;
  height: 91px;
  border-radius: 100%;
  background: var(--primary);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  & h1 {
    font-size: 48px;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: bold;
    color: var(--white);
  }

  @media (max-width: 550px) {
    width: 70px;
    height: 70px;
  }
`;
