import React from 'react';
import Box from '@mui/material/Box';
import { formatCurrency } from 'assets/utils';
import { assetType } from 'jsx/pages/Accounts/Shared/account-list';
import { useCheckLedgerIBANQuery } from 'services/Ledger';
import styled from 'styled-components';
import Button from '../UI/Button';

const CardDetailsModal = ({ content: ledger }) => {
  const isLedgerOK = (ledgerDetail) => {
    if (ledgerDetail.asset_type === assetType.gbp) {
      return (
        ledgerDetail.ledger_status === 'ledger-status-ok' &&
        ledgerDetail.iban &&
        ledgerDetail.bic_swift &&
        ledgerDetail.uk_account_number &&
        ledgerDetail.uk_sort_code
      );
    } else {
      return (
        ledgerDetail.ledger_status === 'ledger-status-ok' &&
        ledgerDetail.iban &&
        ledgerDetail.bic_swift
      );
    }
  };
  const { mutate: checkLedgerIBAN, isLoading } = useCheckLedgerIBANQuery();
  return (
    <Box
      sx={{
        display: 'flex',
        background: '#fff',
        justifyContent: 'center',
        borderRadius: '0.625rem',
      }}
    >
      <LedgerItemDetail className="inner">
        <div className="row" style={{ marginLeft: 0 }}>
          <div className="col-sm-12 col-lg-6 p-4">
            <div className="row">
              {!isLedgerOK(ledger) && (
                <div className="col-12">
                  <h5 className="text-center">
                    Check your account status for IBAN and BIC/SWIFT{' '}
                    <Button
                      type="button"
                      className="btn btn-link p-0"
                      variant=""
                      arial-label="Check Account Status"
                      onClick={() =>
                        checkLedgerIBAN({
                          ledgerId: ledger.ledger_id,
                        })
                      }
                      isLoading={isLoading}
                    >
                      here
                    </Button>
                    .
                  </h5>
                </div>
              )}
              <div className="col-6">
                <div className="mb-2">
                  <h4 className="mb-0">Amount</h4>
                  <p className="mb-0">
                    {formatCurrency(ledger.amount, ledger.asset_type)}
                  </p>
                </div>
                <div className="mb-2">
                  <h4 className="mb-0">Account Number</h4>
                  <p className="mb-0">{ledger.account_number ?? '-'}</p>
                </div>
                <div className="mb-2">
                  <h4 className="mb-0">IBAN</h4>
                  <p className="mb-0">{ledger.iban || '-'}</p>
                </div>
                <div className="mb-2">
                  <h4 className="mb-0">BIC/SWIFT</h4>
                  <p className="mb-0">{ledger.bic_swift || '-'}</p>
                </div>
              </div>
              {ledger.asset_type === 'gbp' && (
                <div className="col-6">
                  <div className="mb-2">
                    <h4 className="mb-0">Account Number</h4>
                    <p className="mb-0">{ledger.uk_account_number}</p>
                  </div>
                  <div className="mb-2">
                    <h4 className="mb-0">UK Sort Code</h4>
                    <p className="mb-0">{ledger.uk_sort_code}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 p-4">
            <div>
              <p className="h4">
                Use these details to add or receive{' '}
                {ledger.asset_type?.toUpperCase()} from bank accounts inside EU
                and SEPA.
              </p>
              {/* <a href="#" className="h4 ledger-link">
                See how to use {ledger.asset_type?.toUpperCase()} account
                details
              </a> */}
              <div className="border-top border-bottom my-2 py-2">
                <p className="h4 mb-3">
                  Incoming payments take 1-2 working days to be added to your
                  account
                </p>
                <p className="h4">SEPA includes countries in the EU and EEA</p>
              </div>
              {/* <a href="#" className="h4 ledger-link">
                See what you can do with account details
              </a> */}
            </div>
          </div>
        </div>
      </LedgerItemDetail>
    </Box>
  );
};

export default CardDetailsModal;

const LedgerItemDetail = styled.div`
  & > .row > div:first-child {
    background: rgba(212, 249, 242, 0.15);
    border: 1px solid rgba(136, 218, 204, 0.4);
    box-sizing: border-box;
    border-radius: 0.5rem;
  }

  & > .row > div:nth-child(2) {
    white-space: pre-line;

    & .ledger-link {
      text-decoration: underline;
      color: var(--primary);
    }
  }
`;
