import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const VerificationModalContainer = styled(Box)(({ theme }) => ({
  maxWidth: '24.38rem',
  // minHeight: '21.38rem',
  width: '100%',
  background: theme.palette.white.main,
  borderRadius: '0.625rem',
  display: 'flex',
  left: '50%',
  top: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  padding: '1.875rem',
}));

export const BasicModalContainer = styled(Box)(() => ({
  width: 'max-content',
  display: 'flex',
  justifyContent: 'center',
  left: '50%',
  top: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  padding: '1.875rem',
}));

export const Actions = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  maxWidth: '20.38rem',
  justifyContent: 'space-between',
}));

export const TransferVerificationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  background: theme.palette.white.main,
}));

export const TransferVerificationContent = styled(Box)(({ theme }) => ({
  marginTop: '0.5rem',
  marginBottom: '1.44rem',
  height: '0.025rem',
  border: '0.5px solid',
  background: theme.palette.grey500.main,
  width: '100%',
}));
export const VerificationContent = styled(Box)(({ theme }) => ({
  marginTop: '1px',
}));

export const ReceiveCode = styled(Box)(() => ({
  marginTop: '0.625rem',
  marginBottom: '1.25rem',
  display: 'flex',
  width: '100%',
}));
export const MinWidth = styled(Box)(({ theme }) => ({
  minWidth: '20.5rem',
}));
export const RecendCode = styled(Box)(({ theme }) => ({
  color: theme.palette.green500.main,
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.9,
  },
}));

export const WhiteContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: '1.875rem 0rem 0rem',
  width: '100%',
}));

export const FlexContainer = styled(Box)(() => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  padding: '0rem 1.175rem',
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `0.025rem solid ${theme.palette.grey500.main}`,
  marginTop: '1.25rem',
}));

export const PaymentList = styled(Box)(() => ({
  width: '100%',
  padding: '1.875rem 1.175rem',
}));

export const PaymentItem = styled(Box)(() => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  marginBottom: '1.5rem',
}));

export const PaymentItemIconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.4rem',
  borderRadius: '50%',
}));
export const AdminVerificationOtpContainer = styled(Box)(({ theme }) => ({
  paddingTop: '1rem',
  paddingLeft: '1rem',
}));
