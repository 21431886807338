import axios from 'axios';
import swal from 'sweetalert';
import {
  adminLogin,
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  verifyLoginOtp,
} from '../../services/AuthService';
import { ADMIN_LOGIN } from '../reducers/AuthReducer';
import { useMutation } from 'react-query';
import { API } from '../../services/Api';
import { TirnuClient } from '../../services/Axios';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const FORGOT_PASSWORD_ACTION = '[ForgotPassword action] forgot password';
export const RESET_PASSWORD_ACTION = '[ResetPassword action] reset password';
export const VERIFY_ACCOUNT_ACTION = '[Verify Account action] verify account';

export function signupAction(...params) {
  return (dispatch) => {
    const formReset = params[1];
    const setLoading = params[2];
    const history = params[3];

    signUp(params)
      .then((response) => {
        // saveTokenInLocalStorage(response.data);
        //  Response on success is {message: 'Signup Successful'}
        dispatch(confirmedSignupAction(response.data));
        // history.push("/login");
        history.push('/login');

        swal({
          title: 'Verify Your account',
          text: `Please Check your Email to proceed with Signup`,
          icon: 'warning',
          button: false,
          closeModal: true,
        });
        // setLoading(false);
      })
      .catch((error) => {
        const errorMessage = formatError(error.response?.data?.message);
        if (error?.response?.data?.message === 'CREDENTIAL_INVALID') {
          formReset();
        }
        setLoading(false);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  const tokenDetailsString = localStorage.getItem('userDetails');
  if (tokenDetailsString) {
    const tokenDetails = JSON.parse(tokenDetailsString);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_API}/api/v1/user/session`, {
        headers: {
          Authorization: `Bearer ${tokenDetails.accessToken}`,
          'x-refresh': `${tokenDetails.refreshToken}`,
        },
      })
      .then((res) => {
        localStorage.removeItem('userDetails');
        history.push('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return {
    type: LOGOUT_ACTION,
  };
}

export function adminLoginAction(
  email,
  password,
  setLoading,
  history,
  enable2FA
) {
  return (dispatch) => {
    adminLogin(email, password)
      .then((response) => {
        dispatch({ type: ADMIN_LOGIN, payload: response.data });
        setLoading(false);
        if (response.data?.twoFAEnabled) {
          enable2FA(response.data.name, response.data.id);
        } else {
          history.push({
            pathname: '/admin-otp/verify',
            search: `?email=${email}`,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          swal({
            title: 'Oops',
            text: `Email or Password is invalid`,
            icon: 'error',
            button: 'Try again',
            closeModal: true,
          });
        } else {
          swal({
            title: 'Internal server error',
            text: `Sorry something went wrong`,
            icon: 'error',
            buttons: 'Try again',
            closeModal: true,
          });
        }
        setLoading(false);
      });
  };
}

export function verifyAdminLoginOtp(otp, email, setLoading, history) {
  return (dispatch) => {
    verifyLoginOtp(otp, email)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        dispatch(loginConfirmedAction(response.data));
        setLoading(false);
        history.push({ pathname: '/dashboard', state: { from: '/login' } });
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          swal({
            title: 'Oops',
            text: `OTP is invalid`,
            icon: 'error',
            button: 'Try again',
            closeModal: true,
          });
        } else {
          swal({
            title: 'Internal server error',
            text: `Sorry something went wrong`,
            icon: 'error',
            buttons: 'Try again',
            closeModal: true,
          });
        }
        setLoading(false);
      });
  };
}

export function onloginRedirect(tokenInfo, history, dispatch) {
  saveTokenInLocalStorage(tokenInfo);
  dispatch(loginConfirmedAction(tokenInfo));
  history.push({ pathname: '/dashboard', state: { from: '/login' } });
}

export function loginAction(email, password, setLoading, history, enable2FA) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        setLoading(false);
        if (response.data?.twoFAEnabled) {
          enable2FA(response.data.name, response.data.id);
        } else {
          onloginRedirect(response.data, history, dispatch);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'CREDENTIAL_INVALID') {
          swal({
            title: 'Oops',
            text: `Email or Password is invalid`,
            icon: 'error',
            button: 'Try again',
            closeModal: true,
          });
        } else if (error?.response?.status === 400) {
          swal({
            title: 'Oops',
            text: `Account does not exist!`,
            icon: 'error',
            button: 'Continue',
            closeModal: true,
          });
        } else if (error?.response?.status === 401) {
          swal({
            title: 'Pending Verification',
            text: `Please verify your email`,
            icon: 'warning',
            buttons: {
              resendLink: 'Resend Link',
            },
            closeModal: true,
          }).then((value) => {
            if (value === 'resendLink') {
              setLoading(true);
              try {
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_API}/api/v1/user/sendAccountVerificationLink`,
                    {
                      email: email,
                    }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      swal({
                        title: 'Verification Link Send',
                        icon: 'success',
                        text: 'Successfully sent verification link',
                        timer: 10000,
                        button: 'Continue',
                        closeModal: false,
                      });
                    }
                  })
                  .then(() => {
                    history.push('./');
                  });
                setLoading(false);
              } catch (e) {
                console.log('The error from the server is:', e);
              }
            }
          });
        }
        setLoading(false);
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

// export function forgetPasswordAction(data, setloading, history) {
//   try {
//     axios
//       .post(`${process.env.REACT_APP_BACKEND_API}/api/v1/user/forgotPassword`, {
//         email: data,
//       })
//       .then((response) => {
//         if (response.status === 200) {
//           swal({
//             title: 'Email Sent',
//             icon: 'success',
//             text: 'Please check your inbox to reset your password',
//             timer: 10000,
//             button: 'Continue',
//             closeModal: false,
//           });
//         }
//       })
//       .then(() => {
//         history.push('./');
//       })
//       .catch((e) => {
//         swal({
//           title: 'Error',
//           text: `User doesnot exist`,
//           icon: 'error',
//           button: 'Ok',
//           closeModal: true,
//         });
//       });
//     setloading(false);
//   } catch (e) {
//     // delete this console log after the work is over
//     console.log('The error from the server is:', e);
//   }

//   return {
//     type: FORGOT_PASSWORD_ACTION,
//     payload: false,
//   };
// }

export function resetPasswordAction(password, token, history, dispatch) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/resetPassword/${token}`,
        {
          password: password,
          passwordConform: password,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          swal({
            title: 'Password Changed',
            icon: 'success',
            text: 'Password changed successfully',
            button: 'Continue',
            closeModal: false,
          });
        }
      })
      .then(() => {
        history.push('./');
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Unauthorized`,
          icon: 'error',
          button: 'Ok',
          closeModal: true,
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    // delete this console log after the work is over
    console.log('The error from the server is:', e);
  }

  return {
    type: RESET_PASSWORD_ACTION,
    payload: false,
  };
}

export function verifyAccountAction(token, setLoading, history) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verifyEmail/${token}`
      )
      .then(() => {
        swal({
          title: 'Account Verified',
          icon: 'success',
          text: 'Your Account has been verified. Please Login to continue',
          timer: 10000,
          button: 'Continue',
          closeModal: false,
        });
      })
      .then(() => {
        history.push('./');
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Unauthorized`,
          icon: 'error',
          button: 'Ok',
          closeModal: true,
        });
      });
    setLoading(false);
  } catch (e) {
    // delete this console log after the work is over
    console.log('The error from the server is:', e);
  }

  return {
    type: VERIFY_ACCOUNT_ACTION,
    payload: false,
  };
}

export function emailRegisterAction(email, accountType, history, dispatch) {
  try {
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/api/v1/user/register`, {
        email: email,
        accountType: accountType,
      })
      .then((response) => {
        history.push(`/email-verify/${response?.data?.data}`);
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Email is invalid!`,
          icon: 'error',
          button: 'Ok',
          className: 'swal-text-center',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function emailVerifyAction(otp, token, history, dispatch) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verify/email/${token}`,
        {
          otp: otp,
        }
      )
      .then(() => {
        history.push(`/account-details/${token}`);
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Invalid code!`,
          icon: 'error',
          button: 'Ok',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}
export const useResend2FAOtp = () => {
  return useMutation(
    API.v1.user.resendOtp,
    async (phoneNumber) =>
      await TirnuClient.post(API.v1.user.resendOtp, {
        phoneNumber: phoneNumber,
      }),
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        swal({
          title: 'Error',
          icon: 'error',
          text: error?.response?.data?.message,
          button: 'Try Again',
        });
      },
    }
  );
};
export function resendEmailCodeVerify(email, onTokenGenerate) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/sendAccountVerificationLink`,
        {
          email: email,
        }
      )
      .then(({ data }) => {
        onTokenGenerate(data.data);
        swal({
          title: 'Success',
          icon: 'success',
          text: 'Verification Сode was sent successfully',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Failed to send code!`,
          icon: 'error',
          button: 'Ok',
        });
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export async function resendPhoneCodeVerify(email, token, onSuccess) {
  try {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/resend-phone-otp`,
        {
          email: email,
          token: token,
        }
      )
      .then(() => {
        onSuccess();
        swal({
          title: 'Success',
          icon: 'success',
          text: 'Verification Сode sent successfully!',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Failed to send code!`,
          icon: 'error',
          button: 'Ok',
        });
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function accountDetailsUpdate(
  firstName,
  lastName,
  phone,
  code,
  token,
  history,
  dispatch
) {
  try {
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/register/update/${token}`,
        {
          first_name: firstName,
          last_name: lastName,
          phone_number: phone,
          phone_number_code: `+${code}`,
        }
      )
      .then(() => {
        history.push(`/account-verify/${token}`);
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Failed to update details!`,
          icon: 'error',
          button: 'Ok',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function phoneVerifyAction(otp, token, history, dispatch) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verify/phone/${token}`,
        {
          otp: otp,
        }
      )
      .then(() => {
        history.push(`/create-password/${token}`);
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Failed to verify otp!`,
          icon: 'error',
          button: 'Ok',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function createUserPassword(password, token, history, dispatch) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/set-password/${token}`,
        {
          password: password,
        }
      )
      .then(() => {
        history.push(`/login`);
      })
      .then(() => {
        swal({
          title: 'Сongratulations!',
          icon: 'success',
          text: 'Registration successfully completed',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err?.response?.data?.message ?? `Failed to set password!`,
          icon: 'error',
          button: 'Ok',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function verifyAccountStep(token, history) {
  try {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verify/token/${token}`
      )
      .then((response) => {
        const data = response.data.data;
        if (
          !data.emailVerified &&
          !data.detailsUpdated &&
          !data.phoneVerified
        ) {
          history.push(`/email-verify/${token}`);
        }
        if (data.emailVerified && !data.detailsUpdated && !data.phoneVerified) {
          history.push(`/account-details/${token}`);
        }
        if (data.emailVerified && data.detailsUpdated && !data.phoneVerified) {
          history.push(`/account-verify/${token}`);
        }
        if (data.emailVerified && data.detailsUpdated && data.phoneVerified) {
          history.push(`/create-password/${token}`);
        }
      })
      .catch((err) => {
        swal({
          title: 'Verification error',
          text: err?.response?.data?.message ?? `Verification token not found!`,
          icon: 'error',
          button: 'Ok',
        });
        history.push(`/`);
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function getUserEmail(token, setEmail, setOTPTimer) {
  try {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verify/token/${token}`
      )
      .then((response) => {
        setEmail(response.data.data.email);
        setOTPTimer && setOTPTimer(response.data.data.phoneCodeExpiration);
      })
      .catch((err) => {
        swal({
          title: 'Verification error',
          text: err?.response?.data?.message ?? `Verification token not found!`,
          icon: 'error',
          button: 'Ok',
        });
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function validateResetPasswordToken(token, setEmail, setOTPTimer) {
  try {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verify/reset-password-token/${token}`
      )
      .then((response) => {
        setEmail(response.data.data.email);
        setOTPTimer && setOTPTimer(response.data.data.phoneCodeExpiration);
      })
      .catch((err) => {
        swal({
          title: 'Verification error',
          text: err?.response?.data?.message ?? `Verification token not found!`,
          icon: 'error',
          button: 'Ok',
        });
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function verifyResetPasswordOTP(token, otp, dispatch, onSuccess) {
  try {
    return axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/user/verify/reset-password-otp/${token}`,
        {
          otp: otp,
        }
      )
      .then(onSuccess)
      .catch((err) => {
        swal({
          title: 'Verification error',
          text: err?.response?.data?.message ?? `Verification token not found!`,
          icon: 'error',
          button: 'Ok',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}

export function forgetPasswordAction(email, history, dispatch) {
  try {
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/api/v1/user/forgotPassword`, {
        email: email,
      })
      .then(() => {
        swal({
          title: 'Password Reset Email Sent',
          icon: 'success',
          text: 'An email has been sent to your email with instructions to reset your password.',
          buttons: {
            ok: 'Close',
          },
          closeModal: false,
          className: 'swal-text-center',
        }).then((value) => {
          if (value === 'ok') {
            history.push('/');
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text:
            err?.response?.data?.message ??
            `Failed to send a reset link to your email!`,
          icon: 'error',
          button: 'Close',
          className: 'swal-text-center',
        });
      })
      .finally(() => {
        dispatch(loadingToggleAction(false));
      });
  } catch (e) {
    console.log('The error from the server is:', e);
  }
}
