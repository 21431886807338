import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EurIcon from 'images/sendMoney/EurIcon';
import accountList from '../../../../pages/Accounts/Shared/account-list';
import React from 'react';

export const TransferFromItem = ({ option }) => {
  const assetType =
    option?.asset_type?.toLowerCase() || option?.value?.toLowerCase();
  const accountType = accountList.find((obj) => obj?.asset_type === assetType);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div
        className={`flag-img ${!option?.asset_type && 'mr-2'}`}
        style={{
          backgroundImage: `url(${accountType?.flag})`,
        }}
      />
      <Typography
        ml={option.amount >= 0 ? '1rem' : 0}
        className="text-truncate"
        sx={{ maxWidth: 'calc(100% - 60px)' }}
      >
        {option?.asset_type && accountType?.currency}
        {option.amount >= 0 && option.amount}
        {option.amount >= 0 && ' | '}
        {option.amount >= 0 ? option.label.toLowerCase() : option.label}
      </Typography>
    </Box>
  );
};
