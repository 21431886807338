import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const AccCreationModalBody = styled(Modal.Body)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;
  padding: 3rem;
`;

const AccCreationModalTitle = styled('p')`
  color: var(--gray-dark);
  font-size: 1.625rem;
  margin-bottom: 40px;
  font-weight: 600;
`;

const AccCreationModalText = styled('p')`
  font-size: 16px;
  line-height: 24px;
  color: var(--gray);
  letter-spacing: -0.02em;
  margin-bottom: 32px;
`;
const AccDropdownDivWrapper = styled('div')`
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
const AccDropdownWrapper = styled('div')`
  position: relative;
  max-width: 300px;
  width: 100%;
  min-height: 16px;
  margin-bottom: 28px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--gray);
  background-color: var(--white);

  & > label {
    color: var(--gray-dark);
  }

  & > div > input.form-control {
    border: 1px solid var(--gray);
    border-radius: 5px;
  }
`;

const AccDropdownSelect = styled('button')`
  position: relative;
  z-index: 1;
  width: 300px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid var(--gray);
  border-radius: 5px;
  color: var(--gray);
  background-color: var(--white);
  color: var(--gray-dark);
  font-size: 16px;
  line-height: 24px;
  padding: 12px 48px 10px 16px;
  margin: 0 auto;

  &:focus {
    border: 1px solid var(--primary);
  }

  & > div > img {
    width: 24px;
    margin-right: 8px;
  }
`;

const AccDropdownSelectArrow = styled('img')`
  transition: all 0.05s linear;
  position: absolute;
  right: 25px;
  transform: ${(props) =>
    props.isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const AccDropDownMenu = styled('div')`
  position: absolute;
  top: 48px;
  left: 0;
  padding: 8px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 20px 66px 0 rgb(34 48 73 / 20%);
  max-height: 440px;
  overflow-y: scroll;
`;

const AccDropDownMenuSearch = styled('div')`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray);

  & > span {
    padding: 10px 16px;
  }

  & > input {
    border: none;
    padding: 10px 16px;
  }
`;

const AccDropDownMenuHeader = styled('p')`
  padding: 0 12px;
  margin: 0 0 8px 0;
  font-size: 0.75rem;
  color: var(--gray-dark);
`;

const AccDropDownMenuItem = styled('button')`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 400;
  color: var(--gray);
  padding: 10px;
  margin-bottom: 2px;
  outline: none;
  background: transparent;
  border: 1px solid transparent;

  &:focus {
    border: 1px solid var(--primary);
    border-radius: 5px;
  }

  &:hover {
    color: var(--white);
    text-decoration: none;
    outline: 0;
    background-color: var(--primary);
    border-radius: 5px;
  }

  & img {
    width: 24px;
    margin-right: 8px;
    margin-top: 4px;
  }
`;

export {
  AccCreationModalBody,
  AccCreationModalTitle,
  AccCreationModalText,
  AccDropdownWrapper,
  AccDropdownSelect,
  AccDropdownSelectArrow,
  AccDropDownMenu,
  AccDropDownMenuSearch,
  AccDropdownDivWrapper,
  AccDropDownMenuHeader,
  AccDropDownMenuItem,
};
