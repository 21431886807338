import gbIcon from 'jsx/components/phoneCountryList/svg/gb.svg';
import euIcon from 'jsx/components/phoneCountryList/svg/eu.svg';

export const assetType = {
  gbp: 'gbp',
  eur: 'eur',
};

export const assetTypeCurrency = {
  eur: '€',
};

const accountList = [
  {
    flag: euIcon,
    title: 'Euro',
    symbol: 'Euro',
    currency: '€',
    asset_type: assetType.eur,
  },
  {
    flag: gbIcon,
    title: 'British pound',
    symbol: 'GBP',
    currency: '£',
    asset_type: assetType.gbp,
  },
  // {
  //     flag: huIcon,
  //     title: "Hungarian forint",
  //     symbol: "FOR",
  // },
  // {
  //     flag: roIcon,
  //     title: "New Romanian leu",
  //     symbol: "LEU",
  // },
  // {
  //     flag: sgIcon,
  //     title: "Singapore dallar",
  //     symbol: "SGD",
  // },
  // {
  //     flag: trIcon,
  //     title: "Turkish lira",
  //     symbol: "TRL",
  // },
  // {
  //     flag: usIcon,
  //     title: "US dallar",
  //     symbol: "USD",
  // },
  // {
  //     flag: arIcon,
  //     title: "Argentine peso",
  //     symbol: "PES",
  // },
  // {
  //     flag: bdIcon,
  //     title: "Bangladesh taka",
  //     symbol: "BAT",
  // },
  // {
  //     flag: caIcon,
  //     title: "Canadian dallar",
  //     symbol: "CAD",
  // },
];
export default accountList;

export const getCurrencySymbol = (currency) => {
  return (
    accountList?.find((obj) => obj.asset_type === currency?.toLowerCase())
      ?.currency || accountList[0].currency
  );
};
