import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  PINRestTSuccessMODALName,
  CardDetailsModalName,
  TransferPaymentSentModalName,
  TransferPinResetModalName,
  TransferVerificationModalName,
  CardsManagePaymentMethod,
  InvestmentTransferVerificationModalName,
  AdminTransferOtpVerification,
} from 'store/actions/ModalActions/ModalActionTypes';
import { ModalCloseAction } from 'store/actions/ModalActions/ModalCloseAction';
import { BasicModalSelector } from 'store/selectors/BasicModalSelector';
import CardDetailsModal from './CardDetailsModal';
import { BasicModalContainer, VerificationModalContainer } from './styles';
import TransferPaymentSentModal from './TransferPaymentSentModal';
import TransferVerificationModal from './TransferVerificationModal';
import TransferPinReset from '../TransferPinReset';
import ResetPinSuccessModal from '../TransferPinReset/SuccessPages';
import CardsManagePaymentMethodModal from './CardsManagePaymentMethodModal/CardsManagePaymentMethodModal';
import InvestmentTransferVerificationModal from './InvesmentTransferVerificationModal';
import AdminTransferOtpVerificationModal from './AdminTransferOtpVerficationModal';
import React from 'react';

const TypeModal = () => {
  const { modalData } = useSelector(BasicModalSelector);

  switch (modalData?.modalName) {
    case TransferVerificationModalName:
      return <TransferVerificationModal content={modalData?.content} />;
    case InvestmentTransferVerificationModalName:
      return (
        <InvestmentTransferVerificationModal content={modalData?.content} />
      );
    case TransferPaymentSentModalName:
      return <TransferPaymentSentModal content={modalData?.content} />;
    case TransferPinResetModalName:
      return <TransferPinReset content={modalData?.content} />;
    case PINRestTSuccessMODALName:
      return <ResetPinSuccessModal content={modalData?.content} />;
    case CardDetailsModalName:
      return <CardDetailsModal content={modalData?.content} />;
    case CardsManagePaymentMethod:
      return <CardsManagePaymentMethodModal content={modalData?.content} />;
    case AdminTransferOtpVerification:
      return <AdminTransferOtpVerificationModal content={modalData?.content} />;
    default:
      return null;
  }
};

const BasicModal = () => {
  const dispatch = useDispatch();
  const { modalData } = useSelector(BasicModalSelector);
  const verifyModals =
    modalData?.modalName === TransferVerificationModalName ||
    modalData?.modalName === InvestmentTransferVerificationModalName ||
    modalData?.modalName === TransferPaymentSentModalName ||
    modalData?.modalName === PINRestTSuccessMODALName ||
    modalData?.modalName === AdminTransferOtpVerification ||
    modalData?.modalName === TransferPinResetModalName;
  const handleClose = () => {
    if (modalData?.modalName === TransferPaymentSentModalName) {
      history.go(0);
    }
    dispatch(ModalCloseAction());
  };
  return (
    <Modal
      open={modalData?.isOpen}
      onClose={
        modalData?.modalName !== TransferVerificationModalName
          ? handleClose
          : undefined
      }
      BackdropProps={{
        sx: {
          background:
            'linear-gradient(95.89deg, rgba(71, 172, 152, 0.4) -13.16%, rgba(136, 218, 204, 0.4) 115.4%)',
        },
      }}
      sx={{
        zIndex: 1305,
      }}
    >
      {verifyModals ? (
        <VerificationModalContainer>
          <TypeModal />
        </VerificationModalContainer>
      ) : (
        <BasicModalContainer>
          <TypeModal />
        </BasicModalContainer>
      )}
    </Modal>
  );
};

export default BasicModal;
