const v1 = '/api/v1';
const INTERGIO_URL = process.env.REACT_APP_INTERGIO_URL;

const API = {
  v1: {
    token: { regen: 'api/v1/user/tokenRegeneration' },
    user: {
      verify: {
        token: v1 + '/user/verify/token/{token}',
        email: v1 + '/user/verify/email/{token}',
        phone: v1 + '/user/verify/phone/{token}',
      },
      count : v1 + '/count/user',
      resendOtp: v1 + '/login/resend-otp',
      setPassword: v1 + '/user/set-password',
      resendVerification: v1 + '/user/sendAccountVerificationLink',
      signup: v1 + '/user/signup',
      fetchDetail: v1 + '/user/self',
      initiateKYC: v1 + '/user/init-kyc',
      fetchKYCStatus: v1 + '/user/kyc-status',
      setKYCVerified: v1 + '/user/kyc-verified',
      uploadUserImage: v1 + '/user/self-image',
      adminUserList: v1 + '/admin/users',
      addAdminUser: v1 + '/admin/users/register',
      addAgentUser: v1 + '/user/register/agent',
      logout: v1 + '/user/logout',
      countries: v1 + '/user/countries',
      riskLevel: v1 + '/user/risk-level',
      companySize: v1 + '/user/company-size',
      companyLegalForm: v1 + '/user/company-legal-form',
      registerCompany: v1 + '/admin/business/register',
      validateEmail: v1 + '/user/validate/email',
      statement: v1 + '/user/statement',
      updatebusinessfromadmin: v1 + '/admin/update/business',
      updatepersonalfromadmin: v1 + '/admin/update/personal',
      updatetransactionlimit: v1 + '/transaction-limit',
    },
    twoFA: {
      generate: v1 + '/2fa/generate',
      save: v1 + '/2fa/save',
      verify: v1 + '/2fa/verify',
      disable: v1 + '/2fa/disable',
    },
    beneficiary: {
      fetchAll: v1 + '/user/beneficiary',
      fetchDetail:
        v1 + '/user/beneficiary-detail/?beneficiary_id={beneficiary_id}',
      update: v1 + '/user/beneficiary',
      delete: v1 + '/user/beneficiary',
    },
    employeeMgmt: {
      checkIsEmployee: v1 + '/employee/check',
      receipt: v1 + '/employee/upload-receipt',
      listEmployee: v1 + '/employee/list',
      addEmployee: v1 + '/employee/add',
      reimbursement: {
        add: v1 + '/employee/add-reimbursement',
        payee: v1 + '/employee/payee-reimbursement',
        payer: v1 + '/employee/payer-reimbursement',
        rejectByPayer: v1 + '/employee/payer-reimbursement',
        total: v1 + '/employee/reimbursement-total',
        pay: v1 + '/employee/complete-reimbursement',
      },
    },
    ledger: {
      switch: v1 + '/user/ledger/switch',
      fetch: v1 + '/user/ledger/all',
      create: v1 + '/user/ledger/add',
      fetchDetail: v1 + '/user/ledger',
      fetchByIBAN: v1 + '/user/ledgerbyiban',
      checkIBAN: v1 + '/user/ledger/check-iban',
      generateAccountChargeToken: v1 + '/user/ledger/topup-token',
      getSavedCards: v1 + '/user/ledger/saved-cards',
      consentAccountVerification: v1 + '/user/accountConsentVerificationCheck/',
      consentAccountVerificationStatusUpdate:
        v1 + '/user/accountConsentVerificationUpdate/',
    },
    cars: {
      getAll: (userId) => v1 + '/card/get-all/' + userId,
      getCardDetails: (cardId) => v1 + '/card/' + cardId,
      changeCardStatus: (cardId) => v1 + '/card/cardstuatus/' + cardId,
      activateCard: v1 + '/card/activate',
      resetPinCard: v1 + '/card/reset-pin',
      showDetails: v1 + '/cardDetails/consent',
    },
    schedule: {
      payment: v1 + '/schedule/payment',
    },
    investment: {
      coinInfo: v1 + '/crypto/market',
      coinBalances: v1 + '/crypto/convert/getDetails',
    },
    transfer: {
      allExchangeRates: v1 + '/transfer/exchange-rates',
      exchangeRate: v1 + '/transfer/exchange-rate',
      transferInternational: v1 + '/transfer/international-agent',
      fulfillInternationalTransfer: v1 + '/transfer/fulfill',
      getBanks: v1 + '/transfer/banks',

      byIBAN: v1 + '/user/directibantransfer',
      beneficiary: v1 + '/user/beneficiaryTransfer',
      buldTransfer: {
        fileUpload: v1 + '/user/transaction/file',
        payment: v1 + '/user/transaction/bulkPayment',
      },
      admin: {
        generateOtp: v1 + '/transaction/otp',
        transfer: v1 + '/user/admin/perform-transaction',
      },
      bankAccount: v1 + '/plaid/fetch-link-token',
      cancelTransfer: v1 + '/transaction/cancel',
      intergiro: {
        consentConfirmation: INTERGIO_URL + '/v1/auth/signin',
        smsVerfication: INTERGIO_URL + '/v1/auth/signin',
        consentConfimation2: INTERGIO_URL + '/v1/baas/transactions/pay/confirm',
        finalApiForConfirmation: v1 + '/transaction/confirm',
        resetPin: INTERGIO_URL + '/v1/auth/reset_passcode',
        accountVerificationConfirmation:
          INTERGIO_URL + '/v1/baas/individuals/consent/confirm',
      },
    },
    schedulePayment: {
      getList: v1 + '/schedule/payment',
      edit: v1 + '/schedule/update',
      delete: v1 + '/schedule/delete',
    },
    fees: {
      all: v1 + '/fees',
      create: v1 + '/fees/create',
      update: v1 + '/fees/update',
      delete: v1 + '/fees/delete',
      createFeeRate : v1 + '/admin/fees',
      updatebulkfees: v1 + '/admin/fees/bulk',
      sendtopupemail: v1 + '/admin/fees/email',
    },
    featureFlag: {
      all: v1 + '/feature',
    },
    transaction: {
      fetch: v1 + '/user/transactions',
      download: v1 + '/user/export_data',
      bulkUpload: v1 + '/user/transaction/file',
      bulkPayment: v1 + '/user/transaction/bulkPayment',
      report: v1 + '/user/transaction/report',
      adminTransaction: v1 + '/user/admin/transactions',
      feeTransaction: v1 + '/user/admin/transactions/fee',
    },
    notification: {
      getList: v1 + '/user/notifications',
      updateStatus: v1 + '/user',
      markAllAsRead: v1 + '/user/notifications/readAll',
      paginatedList: v1 + '/user/notifications/paginated',
      count: v1 + '/user/notification/count',
    },
    emailConfig: {
      fetch: v1 + '/user/email-config',
      update: v1 + '/user/email-config',
    },
    integration: {
      xero: {
        authorize: '/xero/authorize',
        fetchAllAccounts: '/xero/account-all',
        bindXeroAccount: '/xero/bind-account',
        integrateXeroAccount: '/xero/integrate-account',
        syncXeroAccount: '/xero/sync-transaction',
      },
      quickbooks: {
        authorize: '/quickbooks/authorize',
        fetchAllAccounts: '/quickbooks/account-all',
        bindQuickbooksAccount: '/quickbooks/bind-account',
        integrateQuickbooksAccount: '/quickbooks/integrate-account',
        syncQuickbooksAccount: '/quickbooks/sync-transaction',
      },
      slack: {
        integrateSlackAccount: v1 + '/slack/connect',
        fetchSlackConfigs: v1 + '/slack/check',
        toggleSlackConfig: v1 + '/slack/toggle-feature',
        toggleSlackLedger: v1 + '/slack/toggle-ledger',
        disconnectSlack: v1 + '/slack/remove',
      },
    },
    transactionSummary:{
      summary: v1 + '/transaction/summary',
    }
  },
};

export { API };
