import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  EEACountries,
  ISOCountry,
  CountriesNationality,
} from 'assets/ISO_country';
import { useFormikContext } from 'formik';
import PhoneInputFormik from 'jsx/components/PhoneInput/PhoneInputFormik';
import { InputLabel } from 'jsx/components/TextFieldFormik/styles';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import SelectMenu from 'jsx/components/UI/Select';
import {
  useFetchCompanyLegalForm,
  useFetchCompanySize,
  useFetchCountries,
  useFetchRiskLevel,
  useRegisterBusiness,
} from '../../../services/User';

function BusinessUpdateForm({ isSavedOnce, isApproved }) {
  const formik = useFormikContext();
  const { palette } = useTheme();
  const { data: countries } = useFetchCountries();
  const { data: riskLevel } = useFetchRiskLevel();
  const { data: companySize } = useFetchCompanySize();
  const { data: companyLegalForm } = useFetchCompanyLegalForm();
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (selectOptions) {
      setSelectOptions({
        countries: countries?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
        riskLevel: riskLevel?.result?.map((obj) => {
          return {
            label: obj.risk_level,
            value: obj.id,
          };
        }),
        companySize: companySize?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
        companyLegalForm: companyLegalForm?.result?.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        }),
      });
    }
  }, [countries, riskLevel, companySize, companyLegalForm]);

  // const ISOCountryCodes = ISOCountry.filter((country) =>
  //   EEACountries.includes(country.alpha_2)
  // ).map((code) => ({
  //   label: code.name,
  //   value: code.alpha_2,
  // }));

  const isLargeScreen = window.innerWidth > 1200;

  return (
    <Grid container spacing={2}>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="COMPANY NAME"
            type="text"
            name="company_name"
            placeholder="Company name"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="EMAIL"
            type="text"
            name="company_Email"
            placeholder="Email"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6}>
        <Stack>
          <PhoneInputFormik
            name="company_phone_number"
            label="PHONE NUMBER"
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="REGISTRATION DATE"
            type="date"
            name="registration_date"
            placeholder="Registration date"
            max={new Date().toISOString().split('T')[0]}
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <InputLabel>COUNTRY</InputLabel>
          <SelectMenu
            options={selectOptions?.countries}
            value={selectOptions?.countries?.find(
              (country) => country.value == formik.values.country_id
            )}
            onChange={(option) =>
              formik.setFieldValue('country_id', option?.value)
            }
            isRounded
            isDisabled={isSavedOnce || isApproved}
          />
          {!!formik.errors.country_id && !!formik.touched.country_id && (
            <FormHelperText sx={{ color: palette.error.main }}>
              {formik.errors.country_id}
            </FormHelperText>
          )}
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="CITY"
            type="text"
            name="company_city"
            placeholder="City"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="STATE"
            type="text"
            name="company_state"
            placeholder="State"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="STREET"
            type="text"
            name="company_street"
            placeholder="Street"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 3} width="100%">
        <Stack>
          <TextFieldFormik
            label="POSTAL/ZIP CODE"
            type="text"
            name="company_postal_code"
            placeholder="Postal/Zip Code"
            fullWidth
            disabled={isSavedOnce || isApproved}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <InputLabel>Legal Form</InputLabel>
          <SelectMenu
            options={selectOptions?.companyLegalForm}
            value={selectOptions?.companyLegalForm?.find(
              (language) => language.value === formik.values.legal_form_id
            )}
            onChange={(option) =>
              formik.setFieldValue('legal_form_id', option?.value)
            }
            isRounded
            isDisabled={isSavedOnce || isApproved}
          />
          {!!formik.errors.legal_form_id && !!formik.touched.legal_form_id && (
            <FormHelperText sx={{ color: palette.error.main }}>
              {formik.errors.legal_form_id}
            </FormHelperText>
          )}
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <InputLabel>Company Size</InputLabel>
          <SelectMenu
            options={selectOptions?.companySize}
            value={selectOptions?.companySize?.find(
              (language) => language.value === formik.values.company_size_id
            )}
            onChange={(option) =>
              formik.setFieldValue('company_size_id', option?.value)
            }
            isRounded
            isDisabled={isSavedOnce || isApproved}
          />
          {!!formik.errors.company_size_id &&
            !!formik.touched.company_size_id && (
              <FormHelperText sx={{ color: palette.error.main }}>
                {formik.errors.company_size_id}
              </FormHelperText>
            )}
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="TAX ID"
            type="text"
            name="taxId"
            placeholder="Tax ID"
            fullWidth
            disabled={isApproved || isSavedOnce}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="WEBSITE"
            type="text"
            name="website"
            placeholder="Company Website"
            fullWidth
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="VAT NUMBER"
            type="text"
            name="vat_number"
            placeholder="Tax payment identification number"
            fullWidth
            disabled={isApproved || isSavedOnce}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={isLargeScreen ? 4 : 6} width="100%">
        <Stack>
          <TextFieldFormik
            label="LEI CODE"
            type="text"
            name="lei_code"
            placeholder="LEI code"
            fullWidth
            disabled={isApproved || isSavedOnce}
          />
        </Stack>
      </Grid>
      <Grid item mobile={12} tabletS={12}>
        <FormGroup
          error={
            !!formik.errors.accept_terms_condition &&
            !!formik.touched.accept_terms_condition
          }
        >
          <FormControlLabel
            sx={{ alignItems: 'flex-start', m: 0 }}
            control={
              <Checkbox
                onChange={(e) =>
                  formik.setFieldValue(
                    'accept_terms_condition',
                    e.target.checked
                  )
                }
                checked={!!formik.values.accept_terms_condition}
              />
            }
            label={
              <Stack direction="row" ml={1}>
                <Typography>
                  I have read, understood and agree to Tirnu's{' '}
                  <Link
                    href="https://tirnu.com/terms-conditions/"
                    target="_blank"
                  >
                    terms and conditions
                  </Link>{' '}
                  and{' '}
                  <Link href="https://tirnu.com/privacy/" target="_blank">
                    privacy policy
                  </Link>
                  .
                </Typography>
              </Stack>
            }
          />
          <FormHelperText sx={{ color: palette.error.main }}>
            {formik.errors.accept_terms_condition}
          </FormHelperText>
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default BusinessUpdateForm;
