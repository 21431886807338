import TransferDetailsCard from 'jsx/components/TransferDetailsCard/TransferDetailsCard';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetBanks } from 'services/SendMoney';
import { internationalCountries } from '.';
import { InternationalStep2Container } from './styles';

const InternationalStep2 = ({ store, dispatchs, prevStep }) => {
  const history = useHistory();

  const [ledgerHasFunds, setLedgerHasFunds] = useState(true);

  const [sendInternationalData, setSendInternationalData] = useState(
    store?.sendInternationalData
  );
  const transferFrom = store?.transferFrom;
  const { data: bankList = [] } = useGetBanks(
    store?.sendInternationalData?.transferToCountry
  );

  const handleSubmit = async (paymentType) => {
    if (paymentType !== 'bank' && !ledgerHasFunds) return;
    dispatchs.handleInternationalTransfer(paymentType);
  };

  const handlePrevStep = () => {
    prevStep();
  };

  const handleCancel = () => {
    history.go(0);
  };

  useEffect(() => {
    if (
      store?.sendInternationalData?.transferToBank &&
      store?.sendInternationalData?.transferToCountry
    ) {
      const bankName = bankList.find(
        (b) => b._id === store?.sendInternationalData?.transferToBank
      )?.name;
      setSendInternationalData((prev) => ({
        ...prev,
        transferToCountry: internationalCountries.find(
          (c) => c.value === store?.sendInternationalData?.transferToCountry
        )?.label,
        transferToBank: bankName,
      }));
    }
  }, [bankList]);

  return (
    <InternationalStep2Container>
      <TransferDetailsCard
        transferInternational
        transferInternationalRecipient={sendInternationalData}
        transferAmount={sendInternationalData?.transferAmount}
        transferFee={sendInternationalData?.transferFee}
        currency={sendInternationalData?.currency}
        description={sendInternationalData?.description}
        transferFrom={transferFrom}
        onCancel={handleCancel}
        onPrevStep={handlePrevStep}
        onSubmit={handleSubmit}
        isLoading={store?.isLoadingTransfer}
        errors={store?.API_Errors}
        onSelectLedger={(transferFrom) => {
          dispatchs.sendMoneyInternationalValues({
            ...sendInternationalData,
            transferFrom,
          });

          const hasAvailableFunds =
            Number(sendInternationalData?.transferAmount) <
            store?.ledgersOptions.find((obj) => obj.value === transferFrom)
              ?.amount;
          setLedgerHasFunds(hasAvailableFunds);
        }}
        ledgerHasFunds={ledgerHasFunds}
      />
    </InternationalStep2Container>
  );
};

export default InternationalStep2;
