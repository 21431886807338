import React, { useEffect, useState, useContext, useRef } from 'react';
import swal from 'sweetalert';
import {
  Box,
  Button,
  Tooltip,
  Alert,
  TableCell,
  CardActions,
  Typography,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TiEdit } from 'react-icons/ti';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArticleIcon from '@mui/icons-material/Article';
import { ThemeContext } from '../../../../context/ThemeContext';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import {
  MdBlockFlipped,
  MdDeleteForever,
  MdDone,
  MdPersonAddAlt,
} from 'react-icons/md';
import TableWrapperComponent from 'jsx/components/BasicTable/BasicTable';
import { TableRowInvestment } from 'jsx/components/BasicTable/styles';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { IoDocumentText } from 'react-icons/io5';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const DocumentsUpload = () => {
  const state = useSelector((state) => state.auth);
  const history = useHistory();
  const [responseMessage, setResponseMessage] = useState('');
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [documents, setDocuments] = useState({
    businessRegistration: null,
    financialDocuments: null,
    ownershipStructure: null,
    purposeOfAccount: null,
    // riskAssessment: null,
    CRA_sheet: null,
    ClientAgreement: null,
    companyutilitybill: null,
    tax_declaration: null,
    identificationDocumentfront: [],
    identificationDocumentback: [],
    sourceoffund: [],
    utilityBill: [],
    complianceDocumentation: null,
  });
  const steps = ['Onboarding', 'Owner', 'Company'];
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessages, setErrorMessages] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { activeEvent, setActiveEvent, windowWidth } = useContext(ThemeContext);
  const [user, setUser] = useState({});
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [ownerDocs, setOwnerDocs] = useState(1);
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split('/')[2];
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/business/documents?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      )
      .then((res) => {
        setUser(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const headCellsTransactions = [
    {
      sortName: 'name',
      label: 'Name',
    },
    {
      sortName: 'Document',
      label: 'Document',
    },

    {
      sortName: 'email',
      label: 'Email',
    },
    {
      sortName: 'share',
      label: 'Share %',
    },
    {
      sortName: 'status',
      label: 'Status ',
    },

    {
      sortName: 'Upload Document',
      label: 'Upload Document',
    },
  ];
  const headCellsDocuments = [
    {
      sortName: 'Document ',
      label: 'Document',
    },
    {
      sortName: 'status',
      label: 'Status',
    },
    // {
    //   sortName: 'createdDate',
    //   label: 'Registered Date',
    // },
    // {
    //   sortName: 'iban',
    //   label: 'Iban No',
    // },
    // {
    //   sortName: 'status',
    //   label: 'Status',
    // },

    {
      sortName: 'filename',
      label: 'Filename',
    },
    {
      sortName: 'Upload Document',
      label: 'Upload Document',
    },
  ];

  const headCellsOnBoarding = [
    {
      sortName: 'Document ',
      label: 'Document',
    },
    {
      sortName: 'status',
      label: 'Status',
    },
    {
      sortName: 'filename',
      label: 'Filename',
    },
    {
      sortName: 'Upload Document',
      label: 'Upload Document',
    },
  ];

  const showDocument = (document) => {
    const url = URL.createObjectURL(document);
    window.open(url, '_blank');
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (!fileName) return '';
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.substring(
      0,
      fileName.length - extension.length - 1
    );
    return `${nameWithoutExtension.substring(0, maxLength)}...${extension}`;
  };

  const handleFileUpload = (documentType, file, index, username) => {
    if (file && documentType) {
      setDocuments((prevDocuments) => {
        const newDocuments = { ...prevDocuments };

        if (documentType === 'CRA_sheet') {
          if (!isExcelFile(file)) {
            setErrorMessages((prevMessages) => ({
              ...prevMessages,
              CRA_sheet: 'Please upload a valid Excel file for CRA sheet.',
            }));
            newDocuments[documentType] = null;
          } else {
            setErrorMessages((prevMessages) => ({
              ...prevMessages,
              CRA_sheet: null,
            }));
            const updatedFile = new File([file], file.name, {
              type: file.type,
            });
            newDocuments[documentType] = updatedFile;
          }
        } else if (
          [
            'identificationDocumentfront',
            'identificationDocumentback',
            'sourceoffund',
            'utilityBill',
          ].includes(documentType)
        ) {
          const updatedDocuments = [...(newDocuments[documentType] || [])];
          const updatedFile = new File([file], file.name, { type: file.type });
          updatedDocuments[index] = updatedFile;
          newDocuments[documentType] = updatedDocuments;
        } else {
          const updatedFile = new File([file], file.name, { type: file.type });
          newDocuments[documentType] = updatedFile;
        }

        return newDocuments;
      });
    }
  };

  const uploadDocument = () => {
    const newErrorMessages = {};
    const requiredDocuments = [
      // 'businessRegistration',
      // 'financialDocuments',
      'ownershipStructure',
      'sourceoffund',
      'CRA_sheet',
      // 'ClientAgreement',
      'purposeOfAccount',
      // 'riskAssessment',
      'companyutilitybill',
      // 'tax_declaration',
      'identificationDocumentfront',
      // 'identificationDocumentback',
      // 'complianceDocumentation',
      'utilityBill',
    ];

    requiredDocuments.forEach((doc) => {
      if (
        doc === 'identificationDocumentfront' ||
        doc === 'utilityBill' ||
        doc === 'sourceoffund' ||
        doc === 'identificationDocumentback'
      ) {
        newErrorMessages[doc] = [];
        user?.company_details?.directors?.forEach((_, index) => {
          const documentOwner =
            user?.company_details?.directors?.[index]?.email.split('@')[0];
          const matchingDocument = user?.company_details?.documents?.find(
            (docItem) =>
              docItem.document_owner === documentOwner &&
              docItem.documentType === doc
          );

          if (
            !documents[doc]?.[index] &&
            !['approved', 'pending'].includes(matchingDocument?.documentStatus)
          ) {
            newErrorMessages[doc][index] = `Director ${
              index + 1
            } document is required.`;
          }
        });

        if (newErrorMessages[doc].length === 0) {
          delete newErrorMessages[doc];
        }
      } else {
        const documentStatus = user?.company_details?.documents?.find(
          (docItem) => docItem.documentType === doc
        )?.documentStatus;
        if (
          documentStatus &&
          ['approved', 'pending'].includes(documentStatus)
        ) {
          return;
        }

        if (!documents[doc]) {
          newErrorMessages[doc] = `${doc} is required.`;
        }
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
      setGeneralErrorMessage('Some field is missing');
      return;
    } else {
      setErrorMessages({});
      setGeneralErrorMessage('');
    }
    setLoading(true);
    const formData = new FormData();

    let hasFiles = false;

    Object.keys(documents).forEach((docType) => {
      if (documents[docType]) {
        if (Array.isArray(documents[docType])) {
          documents[docType].forEach((file, index) => {
            const username = user?.company_details?.directors?.[index]?.email;
            if (username) {
              if (
                docType === 'identificationDocumentfront' ||
                docType === 'identificationDocumentback' ||
                docType === 'utilityBill' ||
                docType === 'sourceoffund'
              ) {
                formData.append(`${docType}@${username}-${file.name}`, file);
                hasFiles = true;
              } else {
                formData.append(file.name, file);
                hasFiles = true;
              }
            } else {
              console.error(`Username not found for director index ${index}`);
            }
          });
        } else {
          formData.append(docType, documents[docType]);
          hasFiles = true;
        }
      }
    });

    if (!hasFiles) {
      setLoading(false);
      setErrorMessages({ form: 'No files to upload.' });
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/business/documents?id=${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(async (res) => {
        setLoading(false);
        setResponseMessage(res.data.message);
        setErrorMessages({});
        swal({
          title: 'Profile created Successfully',
          icon: 'success',
          text: 'Your profile has been successfully created',
          button: true,
        });
        history.push('/users');
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: 'Company documents upload failed',
          icon: 'error',
          text: error.response?.data?.message || 'An error occurred.',
          button: true,
        });
      });
  };

  const fields = [
    {
      label: 'Identification documents(Front)',
      key: 'identificationDocumentfront',
    },
    {
      label: 'Identification documents(Back)',
      key: 'identificationDocumentback',
    },
    { label: 'Utility documents', key: 'utilityBill' },
    { label: 'Source of fund document', key: 'sourceoffund' },
  ];

  const onBoarding = [
    { label: 'CRA sheet', key: 'CRA_sheet' },
    // { label: 'Client ID', key: 'ClientAgreement' },
    {
      label: 'Purpose of the account',
      key: 'purposeOfAccount',
    },
  ];
  const extractFileNameAfterHyphen = (path) => {
    const fileName = path.split('/').pop();
    const parts = fileName.split('-');
    return parts.slice(1).join('-');
  };

  const handleStepClick = (index) => {
    setActiveStep(index);
    if (index === 1) {
      setOwnerDocs(2);
    } else if (index === 2) {
      setOwnerDocs(3);
    } else {
      setOwnerDocs(1);
    }
  };
  const handleNext = () => {
    setOwnerDocs(ownerDocs + 1);
    setActiveStep(activeStep + 1);
  };
  const handlePrev = () => {
    setOwnerDocs(ownerDocs - 1);
    setActiveStep(activeStep - 1);
  };

  const isExcelFile = (file) => {
    const validExtensions = ['.xls', '.xlsx'];
    const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
    return validExtensions.includes(fileExtension);
  };

  return (
    <div id="main-wrapper" className="show">
      {/* <Nav
        onClick={() => setActiveEvent(!activeEvent)}
        activeEvent={activeEvent}
        onClick2={() => {
          setActiveEvent(!activeEvent);
          toggleHeaderOnMobile();
        }}
        onClick3={() => setActiveEvent(true)}
      /> */}
      <div className={`${activeEvent ? 'rightside-event' : ''} `}>
        <div
          className={`${windowWidth > 2000 ? 'container' : 'container-fluid'}`}
          style={{ minHeight: window.screen.height - 60, paddingTop: '0px' }}
        >
          {/* <CardActions
            sx={{
              maxWidth: '100%',
              margin: 'auto',
              pl: 1,
              pb: 2,
              display: 'flex',
              gap: 4,
            }}
          >
            <Button
              variant={ownerDocs ? 'contained' : ''}
              size="large"
              onClick={() => setOwnerDocs(true)}
              sx={{ maxWidth: 250, fontSize: '1rem' }}
            >
              Owner Document
            </Button>
            <Button
              variant={ownerDocs ? '' : 'contained'}
              size="large"
              onClick={() => setOwnerDocs(false)}
              sx={{ maxWidth: 250, fontSize: '1rem' }}
            >
              Company Document
            </Button>
          </CardActions> */}
          <div className="pb-3 text-black">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step
                    key={label}
                    onClick={() => handleStepClick(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Typography
                        sx={{
                          backgroundColor:
                            activeStep === index ? '#009193' : 'white',
                          color: activeStep === index ? 'white' : 'black',
                          textAlign: 'center',
                          width: '30px',
                          borderRadius: '100%',
                        }}
                      >
                        {activeStep > index ? (
                          <CheckCircle
                            sx={{ fontSize: '20px', color: '#009193' }}
                          />
                        ) : (
                          index + 1
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight:
                            activeStep === index ? 'semi-bold' : 'normal',
                        }}
                      >
                        {label}
                      </Typography>
                    </div>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {(ownerDocs === 1 || activeStep === 0) && (
            <>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <IoDocumentText size={35} style={{ color: '#009193' }} />
                  <h5
                    style={{
                      fontSize: '25px',
                      margin: '0',
                      color: '#5a5a60',
                    }}
                  >
                    Onboarding Documentation
                  </h5>{' '}
                </div>
                <div className="py-4">
                  <p className="p-0 m-0">
                    Please include all the documents required for onboarding and
                    use the "Upload Document" button to upload files.
                  </p>
                  {/* <p className="p-0 m-0 " style={{ color: '#ee3232' }}>
                    Note:
                    <span style={{ color: '#ee3232' }}>
                      Client ID is the document of the agreement held in between
                      clientAgreement and banking agent
                    </span>
                    .
                  </p> */}
                </div>{' '}
                <div style={{ borderRadius: '5px', backgroundColor: 'white' }}>
                  <div style={{ padding: '16px' }}>
                    {' '}
                    {/* <h5 className="pb-1 mx-0 " style={{ fontSize: '20px' }}>
                  Onboarding
                </h5> */}
                    <TableWrapperComponent
                      headerCells={headCellsOnBoarding}
                      sx={{ width: '100%' }}
                    >
                      {onBoarding?.map(({ label, key }, index) => (
                        <TableRowInvestment
                          sx={{
                            padding: '15px',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                          className="text-capitalize "
                          key={index}
                        >
                          <TableCell sx={tableCellStyle}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginRight: '-15px',
                              }}
                            >
                              {/* <ImagePlaceholder>{getInitials(name)}</ImagePlaceholder> */}
                              <Box
                                sx={{
                                  paddingTop: '5px',
                                  textTransform: 'capitalize',
                                  color: '#8B9DB8',
                                  fontSize: '16px',
                                  fontWeight: '500',
                                }}
                              >
                                {label}
                              </Box>
                            </Box>
                            {errorMessages[key] && (
                              <Alert severity="error" sx={{ marginTop: 1 }}>
                                {errorMessages[key]}
                              </Alert>
                            )}
                          </TableCell>

                          <TableCell sx={tableCellStyle}>
                            {documents[key]?.name ? (
                              <FileUploadIcon style={{ color: '#339193' }} />
                            ) : (
                              (() => {
                                const matchingDocument =
                                  user?.company_details?.documents?.find(
                                    (doc) => doc?.documentType === key
                                  );

                                if (
                                  matchingDocument &&
                                  matchingDocument.documentStatus
                                ) {
                                  switch (matchingDocument.documentStatus) {
                                    case 'pending':
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '10px',
                                            color: 'goldenrod',
                                          }}
                                        >
                                          <div>
                                            {' '}
                                            <CircleIcon
                                              style={{
                                                fontSize: 18,
                                              }}
                                            />
                                          </div>
                                          <Box>
                                            <div
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {matchingDocument.documentStatus}
                                            </div>
                                          </Box>
                                        </div>
                                      );
                                    case 'rejected':
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '10px',
                                            color: '#ee3232',
                                          }}
                                        >
                                          <div>
                                            {' '}
                                            <CancelIcon
                                              style={{
                                                fontSize: 18,
                                              }}
                                            />
                                          </div>
                                          <Box>
                                            <div
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {matchingDocument.documentStatus}
                                            </div>
                                          </Box>
                                        </div>
                                      );
                                    case 'approved':
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '10px',
                                            color: 'green',
                                          }}
                                        >
                                          <div>
                                            {' '}
                                            <MdDone
                                              style={{
                                                fontSize: 18,
                                              }}
                                            />
                                          </div>
                                          <Box>
                                            <div
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {matchingDocument.documentStatus}
                                            </div>
                                          </Box>
                                        </div>
                                      );
                                    default:
                                      return (
                                        <Box>
                                          {matchingDocument.documentStatus}
                                        </Box>
                                      );
                                  }
                                } else {
                                  return <Box>No file</Box>;
                                }
                              })()
                            )}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            {' '}
                            <Tooltip
                              title={
                                documents[key]?.[index]?.name &&
                                documents[key][index].name
                              }
                            >
                              <Button
                                variant="text"
                                sx={{
                                  color: '#808080',
                                  mb: 1,
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  textTransform: 'none',
                                }}
                                onClick={() => {
                                  try {
                                    if (
                                      documents[key] &&
                                      !user?.company_details?.documents
                                    ) {
                                      showDocument(documents[key]);
                                    } else {
                                      showDocument(
                                        user.company_details.documents.find(
                                          (doc) => doc?.documentType === key
                                        )?.documentPath
                                      );
                                    }
                                  } catch (error) {}
                                }}
                              >
                                {documents[key] ? (
                                  truncateFileName(documents[key].name)
                                ) : user?.company_details?.documents?.find(
                                    (doc) => doc?.documentType === key
                                  )?.documentPath ? (
                                  <Box>
                                    {extractFileNameAfterHyphen(
                                      user.company_details.documents.find(
                                        (doc) => doc?.documentType === key
                                      )?.documentPath
                                    )}
                                  </Box>
                                ) : (
                                  <Box>No file</Box>
                                )}
                              </Button>
                            </Tooltip>
                          </TableCell>
                          {/* <TableCell sx={tableCellStyle}>123</TableCell>
                    <TableCell sx={tableCellStyle}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <CiSaveUp2 color="#8B9DB8" className="mt-1" size={20} />

                        <MdBlockFlipped color="#8B9DB8" size={20} />
                      </div>
                    </TableCell> */}

                          <TableCell sx={tableCellStyle}>
                            <Button
                              component="label"
                              startIcon={<ArticleIcon />}
                              sx={{
                                textTransform: 'none',
                                color: documents[key]?.name
                                  ? '#339193'
                                  : '#8B9DB8 ',
                                padding: '4px 8px',
                                fontSize: '0.875rem',
                                border: documents[key]?.name
                                  ? '1px solid #339193'
                                  : '1px solid #8B9DB8',
                                minHeight: '32px',
                              }}
                              disabled={['pending', 'approved'].includes(
                                user?.company_details?.documents?.find(
                                  (doc) => doc?.documentType === key
                                )?.documentStatus
                              )}
                            >
                              Upload Document
                              <input
                                type="file"
                                hidden
                                onChange={(e) =>
                                  handleFileUpload(key, e.target.files[0])
                                }
                              />
                            </Button>{' '}
                          </TableCell>
                        </TableRowInvestment>
                      ))}
                    </TableWrapperComponent>
                  </div>
                </div>
                {/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                size="small"
                sx={{ maxWidth: 150 }}
                onClick={handleNext}
              >
                Next
              </Button>
            </div> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    sx={{ maxWidth: 150 }}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </>
          )}
          {(ownerDocs === 2 || activeStep === 1) && (
            <div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <MdPersonAddAlt size={35} style={{ color: '#009193' }} />
                <h5
                  style={{
                    fontSize: '25px',
                    margin: '0',
                    color: '#5a5a60',
                  }}
                >
                  Owners Documentation
                </h5>{' '}
              </div>
              <div className="py-4">
                <p className="p-0 m-0">
                  Please add all owners and use the "Upload Document" button to
                  upload IDs.
                </p>
                <p className="p-0 m-0">
                  Ownsership documented:
                  <span style={{ color: 'green' }}>100/100%</span>ID uploaded:
                  <span style={{ color: 'red' }}>0/1</span>.Completed (person):
                  <span style={{ color: 'red' }}>0/1</span>.
                </p>
              </div>{' '}
              <div style={{ borderRadius: '5px', backgroundColor: 'white' }}>
                <div style={{ padding: '16px' }}>
                  {' '}
                  <h5 className="pb-1 mx-0 " style={{ fontSize: '20px' }}>
                    Owners
                  </h5>
                  <TableWrapperComponent headerCells={headCellsTransactions}>
                    {user?.company_details?.directors?.map((item, index) => (
                      <React.Fragment key={index}>
                        {fields?.map(({ label, key }) => (
                          <>
                            <TableRowInvestment
                              sx={{
                                padding: '15px',
                                '&:hover': {
                                  cursor: 'pointer',
                                },
                              }}
                              key={index}
                            >
                              <TableCell sx={tableCellStyle}>
                                {item?.full_name}
                              </TableCell>
                              <TableCell sx={tableCellStyle}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginRight: '-15px',
                                  }}
                                  key={index}
                                >
                                  <Box
                                    sx={{
                                      paddingTop: '5px',
                                      textTransform: 'capitalize',
                                      color: '#8B9DB8',
                                      fontSize: '14px',
                                      fontWeight: '500',
                                    }}
                                    className="text-capitalize "
                                  >
                                    {label}
                                  </Box>
                                </Box>
                                {errorMessages[key]?.[index] && (
                                  <Alert severity="error" sx={{ marginTop: 1 }}>
                                    {errorMessages[key][index]}
                                  </Alert>
                                )}
                              </TableCell>

                              <TableCell sx={tableCellStyle}>
                                {item?.email}
                              </TableCell>

                              {/* <TableCell sx={tableCellStyle}>
                            <Box
                              sx={{
                                paddingTop: '5px',
                                textTransform: 'capitalize',
                              }}
                            >
                              {(() => {
                                const emailUsername =
                                  item.email.split('@')[0];
                                const matchingDocument =
                                  user?.documents?.find(
                                    (doc) =>
                                      doc?.document_owner === emailUsername &&
                                    doc?.documentType === key
                                  );

                                if (
                                  matchingDocument &&
                                  matchingDocument.documentStatus
                                ) {
                                  switch (matchingDocument.documentStatus) {
                                    case 'pending':
                                      return (
                                        <Box style={{ color: 'yellow' }}>
                                          {matchingDocument.documentStatus}
                                        </Box>
                                      );
                                    case 'rejected':
                                      return (
                                        <Box style={{ color: 'red' }}>
                                          {matchingDocument.documentStatus}
                                        </Box>
                                      );
                                    case 'approved':
                                      return (
                                        <Box style={{ color: 'green' }}>
                                          {matchingDocument.documentStatus}
                                        </Box>
                                      );
                                    default:
                                      return (
                                        <Box>
                                          {matchingDocument.documentStatus}
                                        </Box>
                                      );
                                  }
                                } else {
                                  return <Box>No file</Box>;
                                }
                              })()}
                            </Box>
                          </TableCell> */}
                              <TableCell sx={tableCellStyle}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginRight: '-15px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      paddingTop: '5px',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {item?.share_holding &&
                                      `${item.share_holding}%`}{' '}
                                    {item?.share && `${item.share}%`}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell sx={tableCellStyle}>
                                {documents[key]?.[index]?.name ? (
                                  <FileUploadIcon
                                    style={{ color: '#339193' }}
                                  />
                                ) : (
                                  (() => {
                                    const emailUsername =
                                      item.email.split('@')[0];
                                    const matchingDocument =
                                      user?.company_details?.documents?.find(
                                        (doc) =>
                                          (doc?.document_owner ===
                                            emailUsername) &
                                          (doc?.documentType === key)
                                      );

                                    if (
                                      matchingDocument &&
                                      matchingDocument.documentStatus
                                    ) {
                                      switch (matchingDocument.documentStatus) {
                                        case 'pending':
                                          return (
                                            <div
                                              style={{
                                                display: 'flex',
                                                gap: '10px',
                                                color: '#b3b300',
                                              }}
                                            >
                                              <div>
                                                {' '}
                                                <CircleIcon
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                />
                                              </div>
                                              <Box>
                                                <div
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {
                                                    matchingDocument.documentStatus
                                                  }
                                                </div>
                                              </Box>
                                            </div>
                                          );
                                        case 'rejected':
                                          return (
                                            <div
                                              style={{
                                                display: 'flex',
                                                gap: '10px',
                                                color: '#ee3232',
                                              }}
                                            >
                                              <div>
                                                {' '}
                                                <CancelIcon
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                />
                                              </div>
                                              <Box>
                                                <div
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {
                                                    matchingDocument.documentStatus
                                                  }
                                                </div>
                                              </Box>
                                            </div>
                                          );
                                        case 'approved':
                                          return (
                                            <div
                                              style={{
                                                display: 'flex',
                                                gap: '10px',
                                                color: 'green',
                                              }}
                                            >
                                              <div>
                                                {' '}
                                                <MdDone
                                                  style={{
                                                    fontSize: 18,
                                                  }}
                                                />
                                              </div>
                                              <Box>
                                                <div
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {
                                                    matchingDocument.documentStatus
                                                  }
                                                </div>
                                              </Box>
                                            </div>
                                          );
                                        default:
                                          return (
                                            <Box>
                                              {matchingDocument.documentStatus}
                                            </Box>
                                          );
                                      }
                                    } else {
                                      return <Box>No file</Box>;
                                    }
                                  })()
                                )}
                              </TableCell>

                              <TableCell sx={tableCellStyle}>
                                <Button
                                  component="label"
                                  startIcon={<ArticleIcon />}
                                  sx={{
                                    textTransform: 'none',
                                    color: documents[key]?.[index]?.name
                                      ? '#339193'
                                      : '#8B9DB8',
                                    padding: '4px 8px',
                                    fontSize: '0.875rem',
                                    border: documents[key]?.[index]?.name
                                      ? '1px solid #339193'
                                      : '1px solid #8B9DB8',
                                    minHeight: '32px',
                                  }}
                                  disabled={['pending', 'approved'].includes(
                                    user?.company_details?.documents?.find(
                                      (doc) =>
                                        doc?.document_owner ===
                                          item.email.split('@')[0] &&
                                        doc?.documentType === key
                                    )?.documentStatus
                                  )}
                                >
                                  Upload Document
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(
                                        key,
                                        e.target.files[0],
                                        index,
                                        item?.email
                                      )
                                    }
                                  />
                                </Button>
                              </TableCell>
                            </TableRowInvestment>
                          </>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableWrapperComponent>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {(ownerDocs === 3 || activeStep === 2) && (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <BsFillBuildingsFill size={35} style={{ color: '#009193' }} />
                <h5
                  style={{
                    fontSize: '25px',
                    margin: '0',
                    color: '#5a5a60',
                  }}
                >
                  Company Documentation
                </h5>{' '}
              </div>
              <div className="py-4">
                <p className="p-0 m-0">
                  Please use the "Upload Document" button to upload all the
                  required document.(max file size: 21MB per document )
                </p>
                <p className="p-0 m-0">
                  Required documents uploaded:
                  <span style={{ color: 'green' }}>2/2</span>.
                </p>
                 <p className="p-0 m-0 " style={{ color: '#ee3232' }}>
                    Note:
                    <span style={{ color: '#ee3232' }}>
                     Financial document is not required for the startup company.
                    </span>
                    .
                  </p>
              </div>{' '}
              <div style={{ borderRadius: '5px', backgroundColor: 'white' }}>
                <div style={{ padding: '16px' }}>
                  {' '}
                  <h5 className="pb-1 mx-0 " style={{ fontSize: '20px' }}>
                    Documents
                  </h5>
                  <TableWrapperComponent
                    headerCells={headCellsDocuments}
                    sx={{ width: '100%' }}
                  >
                    {[
                      {
                        label: 'Business registration document',
                        key: 'businessRegistration',
                      },
                      {
                        label: 'Financial document',
                        key: 'financialDocuments',
                      },
                      {
                        label: 'Ownership structure doc',
                        key: 'ownershipStructure',
                      },

                      //  { label: 'Risk assessment', key: 'riskAssessment' },
                      {
                        label: 'Company Utility Bill',
                        key: 'companyutilitybill',
                      },
                      {
                        label: 'Tax Return Document',
                        key: 'tax_declaration',
                      },
                      {
                        label: 'Compliance documentation',
                        key: 'complianceDocumentation',
                      },
                    ].map(({ label, key }, index) => (
                      <TableRowInvestment
                        sx={{
                          padding: '15px',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        className="text-capitalize "
                        key={index}
                      >
                        <TableCell sx={tableCellStyle}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginRight: '-15px',
                            }}
                          >
                            {/* <ImagePlaceholder>{getInitials(name)}</ImagePlaceholder> */}
                            <Box
                              sx={{
                                paddingTop: '5px',
                                textTransform: 'capitalize',
                                color: '#8B9DB8',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              {label}
                            </Box>
                          </Box>
                          {errorMessages[key] && (
                            <Alert severity="error" sx={{ marginTop: 1 }}>
                              {errorMessages[key]}
                            </Alert>
                          )}
                        </TableCell>

                        <TableCell sx={tableCellStyle}>
                          {documents[key]?.name ? (
                            <FileUploadIcon style={{ color: '#339193' }} />
                          ) : (
                            (() => {
                              const matchingDocument =
                                user?.company_details?.documents?.find(
                                  (doc) => doc?.documentType === key
                                );

                              if (
                                matchingDocument &&
                                matchingDocument.documentStatus
                              ) {
                                switch (matchingDocument.documentStatus) {
                                  case 'pending':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: '#b3b300',
                                        }}
                                      >
                                        <div>
                                          {' '}
                                          <CircleIcon
                                            style={{
                                              fontSize: 18,
                                            }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  case 'rejected':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: '#ee3232',
                                        }}
                                      >
                                        <div>
                                          {' '}
                                          <CancelIcon
                                            style={{
                                              fontSize: 18,
                                            }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  case 'approved':
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '10px',
                                          color: 'green',
                                        }}
                                      >
                                        <div>
                                          {' '}
                                          <MdDone
                                            style={{
                                              fontSize: 18,
                                            }}
                                          />
                                        </div>
                                        <Box>
                                          <div
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {matchingDocument.documentStatus}
                                          </div>
                                        </Box>
                                      </div>
                                    );
                                  default:
                                    return (
                                      <Box>
                                        {matchingDocument.documentStatus}
                                      </Box>
                                    );
                                }
                              } else {
                                return <Box>No file</Box>;
                              }
                            })()
                          )}
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          {' '}
                          <Tooltip
                            title={
                              documents[key]?.[index]?.name &&
                              documents[key][index].name
                            }
                          >
                            <Button
                              variant="text"
                              sx={{
                                color: '#808080',
                                mb: 1,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                              }}
                              onClick={() => {
                                if (documents[key]) {
                                  showDocument(documents[key]);
                                }
                              }}
                            >
                              {documents[key] ? (
                                truncateFileName(documents[key].name)
                              ) : user?.company_details?.documents?.find(
                                  (doc) => doc?.documentType === key
                                )?.documentPath ? (
                                <Box>
                                  {extractFileNameAfterHyphen(
                                    user.company_details.documents.find(
                                      (doc) => doc?.documentType === key
                                    )?.documentPath
                                  )}
                                </Box>
                              ) : (
                                <Box>No file</Box>
                              )}
                            </Button>
                          </Tooltip>
                        </TableCell>
                        {/* <TableCell sx={tableCellStyle}>123</TableCell>
                    <TableCell sx={tableCellStyle}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <CiSaveUp2 color="#8B9DB8" className="mt-1" size={20} />

                        <MdBlockFlipped color="#8B9DB8" size={20} />
                      </div>
                    </TableCell> */}

                        <TableCell sx={tableCellStyle}>
                          <Button
                            component="label"
                            startIcon={<ArticleIcon />}
                            sx={{
                              textTransform: 'none',
                              color: documents[key]?.name
                                ? '#339193'
                                : '#8B9DB8 ',
                              padding: '4px 8px',
                              fontSize: '0.875rem',
                              border: documents[key]?.name
                                ? '1px solid #339193'
                                : '1px solid #8B9DB8',
                              minHeight: '32px',
                            }}
                            disabled={['pending', 'approved'].includes(
                              user?.company_details?.documents?.find(
                                (doc) => doc?.documentType === key
                              )?.documentStatus
                            )}
                          >
                            Upload Document
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleFileUpload(key, e.target.files[0])
                              }
                            />
                          </Button>{' '}
                        </TableCell>
                      </TableRowInvestment>
                    ))}
                  </TableWrapperComponent>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingRight: '100px',
                  marginTop: '50px',
                }}
              >
                {responseMessage && (
                  <p style={{ color: 'green' }}>{responseMessage}</p>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Button
                  component={LoadingButton}
                  loading={loading}
                  variant="contained"
                  size="small"
                  sx={{ maxWidth: 150 }}
                  onClick={uploadDocument}
                >
                  {!loading && 'Submit'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const tableCellStyle = {
  minWidth: '100px',

  overflow: 'hidden',
  color: '#8B9DB8',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export default DocumentsUpload;
