import { Link } from 'react-router-dom';
import {
  accounts,
  dashboard,
  logout,
  supports,
  transactions,
  transfers,
  users,
} from './Icons';
import { AccountType } from '../../index';
import AccountListSidebar from '../../pages/Accounts/AccountList/AccountListSidebar';
import React from 'react';
import { MM } from './SideBar';

const UserSideBar = ({
  path,
  user,
  logoutHandler,
  props,
  openAccCreationModal,
}) => {
  return (
    <div className="deznav bg-white">
      {/* <PerfectScrollbar className="deznav-scroll"> */}
      <MM className="metismenu" id="menu">
        <li
          className={`${
            path === 'dashboard' || path === '' ? 'mm-active' : ''
          }`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to="/dashboard">
            {dashboard}
            <span className="nav-text">Dashboard</span>
          </Link>
        </li>
        {/*{![AccountType.AG, AccountType.A].includes(user?.accountType) && (*/}
        {/*  <li*/}
        {/*    className={`mb-1 ${path === 'accounts' ? 'mm-active' : ''}`}*/}
        {/*  >*/}
        {/*    <Link*/}
        {/*      className="has-arrow ai-icon"*/}
        {/*      to="/accounts"*/}
        {/*      onClick={() => this.props.onClick()}*/}
        {/*    >*/}
        {/*      {accounts}*/}
        {/*      <span className="nav-text">Accounts</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        <li className={`mb-1 ${path === 'accounts' ? 'mm-active' : ''}`}>
          <Link
            className="has-arrow ai-icon"
            to="/accounts"
            onClick={() => props.onClick()}
          >
            {accounts}
            <span className="nav-text">Accounts</span>
          </Link>
        </li>
        {<AccountListSidebar onAccountClick={() => props.onClick()} />}
        {user?.ledgers?.length < user?.limit?.account && (
          <li>
            <a
              onClick={
                user?.kyc?.status === 'approved'
                  ? openAccCreationModal
                  : undefined
              }
              className="cursor-pointer ai-icon"
            >
              + <span className="nav-text">Open an account</span>
            </a>
          </li>
        )}
        {/*{(user?.accountType === AccountType.B ||*/}
        {/*  user?.accountType === AccountType.P) && (*/}
        {/*  <li className={`${path === 'investment' ? 'mm-active' : ''}`}>*/}
        {/*    <Link*/}
        {/*      className="has-arrow ai-icon"*/}
        {/*      to="/investment"*/}
        {/*      onClick={() => this.props.onClick()}*/}
        {/*    >*/}
        {/*      {investment}*/}
        {/*      <span className="nav-text">Investment</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        <li
          className={`${path === 'transactions' ? 'mm-active' : ''}`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to={'/transactions'}>
            {transactions}
            <span className="nav-text">Transactions</span>
          </Link>
        </li>

        {/*{user?.accountType === AccountType.S && (*/}
        {/*  <li*/}
        {/*    className={`${*/}
        {/*      path === 'remittance-transactions' ? 'mm-active' : ''*/}
        {/*    }`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link*/}
        {/*      className="has-arrow ai-icon"*/}
        {/*      to="/remittance-transactions"*/}
        {/*    >*/}
        {/*      {transfers}*/}
        {/*      <span className="nav-text">Remittance Transactions</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {/*{user?.accountType === AccountType.S && (*/}
        {/*  <li*/}
        {/*    className={`${path === 'crypto-balance' ? 'mm-active' : ''}`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link className="has-arrow ai-icon" to="/crypto-balance">*/}
        {/*      {transfers}*/}
        {/*      <span className="nav-text">Cyrto Balance</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {/*{![AccountType.AG, AccountType.A].includes(user?.accountType) && (*/}
        {/*  <li*/}
        {/*    className={`${path === 'send-money' ? 'mm-active' : ''}`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link*/}
        {/*      className="has-arrow ai-icon"*/}
        {/*      to={*/}
        {/*        user.accountType === AccountType.S*/}
        {/*          ? '/admin-send-money'*/}
        {/*          : '/send-money'*/}
        {/*      }*/}
        {/*    >*/}
        {/*      {transfers}*/}
        {/*      <span className="nav-text">Send money</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        <li
          className={`${path === 'send-money' ? 'mm-active' : ''}`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to={'/send-money'}>
            {transfers}
            <span className="nav-text">Send money</span>
          </Link>
        </li>
        {/*{user?.accountType === AccountType.S && (*/}
        {/*  <li*/}
        {/*    className={`${path === 'system-users' ? 'mm-active' : ''}`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link className="has-arrow ai-icon" to="/fees-setup">*/}
        {/*      {transfers}*/}
        {/*      <span className="nav-text">Fees Setup</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {/*{user?.accountType === AccountType.S && (*/}
        {/*  <li*/}
        {/*    className={`${path === 'system-users' ? 'mm-active' : ''}`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link className="has-arrow ai-icon" to="/exchange-rate">*/}
        {/*      {transfers}*/}
        {/*      <span className="nav-text">Exchange Rates</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {/*{isFeatureEnabled(features.cards) &&*/}
        {/*  (user?.accountType === AccountType.B ||*/}
        {/*    user?.accountType === AccountType.P) && (*/}
        {/*    <li className={`${path === 'cards' ? 'mm-active' : ''}`}>*/}
        {/*      <Link*/}
        {/*        className="has-arrow ai-icon"*/}
        {/*        to="/cards"*/}
        {/*        onClick={() => this.props.onClick()}*/}
        {/*      >*/}
        {/*        {cards}*/}
        {/*        <span className="nav-text">Cards</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*  )}*/}
        {/*{AccountType.B === user.accountType && (*/}
        {/*  <li*/}
        {/*    className={`${*/}
        {/*      path === 'employee-management' ? 'mm-active' : ''*/}
        {/*    }`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link className="has-arrow ai-icon" to="/employee-management">*/}
        {/*      {EmployeeMgmt}*/}
        {/*      <span className="nav-text">Employee Management</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {/*{AccountType.P === user.accountType && enableReimbursement && (*/}
        {/*  <li*/}
        {/*    className={`${*/}
        {/*      path === 'request-reimbursement' ? 'mm-active' : ''*/}
        {/*    }`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link*/}
        {/*      className="has-arrow ai-icon"*/}
        {/*      to="/request-reimbursement"*/}
        {/*    >*/}
        {/*      {EmployeeMgmt}*/}
        {/*      <span className="nav-text">Reimbursement</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}
        {/*{AccountType.B === user.accountType && (*/}
        {/*  <li*/}
        {/*    className={`${path === 'integration' ? 'mm-active' : ''}`}*/}
        {/*    onClick={() => this.props.onClick()}*/}
        {/*  >*/}
        {/*    <Link className="has-arrow ai-icon" to="/integration">*/}
        {/*      {integration}*/}
        {/*      <span className="nav-text">Integration</span>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*)}*/}

        <li className={`${path === 'supports' ? 'mm-active' : ''}`}>
          <Link
            className="has-arrow ai-icon"
            to="#"
            onClick={() => props.onClick()}
          >
            {supports}
            <span className="nav-text">Supports</span>
          </Link>
        </li>

        <li className="log-out">
          <Link
            className="has-arrow ai-icon"
            to="#"
            onClick={() => logoutHandler()}
          >
            {logout}
            <span className="nav-text">Log Out</span>
          </Link>
        </li>
      </MM>
    </div>
  );
};

export default UserSideBar;
