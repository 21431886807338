import { Box, Select, styled, Button, TableRow } from '@mui/material';

export const SelectTransaction = styled(Select)(({ theme }) => ({
  zIndex: 0,
  color: theme.palette.grey600.main,
  fontSize: '1rem',
  width: '100%',
  maxWidth: '11.4rem',
  height: '2.5rem',
  border: `0.0625rem solid ${theme.palette.grey500.main}`,
  '&.Mui-focused': {
    '& .MuiSelect-select': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    border: 'none',
    borderRadius: '0.625rem',
    zIndex: 0,
    '&:focus': {
      border: 'none',
    },
  },
  '& .MuiSelect-icon': {
    zIndex: 0,
  },
}));

export const StatusBlock = styled(Box)(({ theme, status }) => ({
  width: 'fit-content',
  height: '1.62rem',
  border:
    status === 'rejected'
      ? `0.0625rem solid ${theme.palette.error.main}`
      : `0.0625rem solid ${theme.palette.green500.main}`,
  padding: '0.188rem 0.75rem',
  borderRadius: '0.625rem',
  color:
    status === 'rejected'
      ? theme.palette.error.main
      : theme.palette.green500.main,
}));

export const ActionBlock = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  height: '1.62rem',
  border: `0.0625rem solid ${theme.palette.grey500.main}`,
  padding: '0.188rem 0.75rem',
  borderRadius: '0.625rem',
  color: theme.palette.grey500.main,
}));

export const ButtonAction = styled(Button)(
  ({ theme, balance, unsubscribe }) => ({
    border: `0.0625rem solid ${theme.palette.green500.main}`,
    background: unsubscribe
      ? theme.palette.green500.main
      : theme.palette.green110.main,
    borderRadius: '0.31rem',
    color: unsubscribe ? theme.palette.white.main : theme.palette.green500.main,
    fontSize: '0.88rem',
    fontHeight: '1.5rem',
    fontWeight: 500,
    width: balance ? '3.5rem' : '5.5rem',
    height: '1.75rem',
    boxShadow: 'none',
    textTransform: 'capitalize',
    marginRight: '0.75rem',
    '&:hover': {
      color: theme.palette.white.main,
      background: theme.palette.green500.main,
    },
  })
);
export const DeleteButtonAction = styled(Button)(({ theme, balance }) => ({
  border: `0.0625rem solid #db2434`,
  background: theme.palette.white.main,
  borderRadius: '0.31rem',
  color: '#db2434',
  fontSize: '0.88rem',
  fontHeight: '1.5rem',
  fontWeight: 500,
  width: balance ? '3.5rem' : '5.5rem',
  height: '1.75rem',
  boxShadow: 'none',
  textTransform: 'capitalize',
  marginRight: '0.75rem',
  '&:hover': {
    color: theme.palette.white.main,
    background: '#DB2434',
  },
}));
export const TableRowInvestment = styled(TableRow)(({ theme }) => ({
  padding: '1.25rem 0.94rem',
  '&:hover': {
    background: theme.palette.grey300.second,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
