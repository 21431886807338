import { Box, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoIcon from '@mui/icons-material/Info';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  transferPasscodeVerificationSchema,
  transferSMSVerificationSchema,
} from 'schemas/transferVerificationSchema.schema';
export const modalBackDropColor = 'rgba(0, 145, 147, 0.5)';
import { Modal } from 'react-bootstrap';
import {
  TransferPaymentSentModalName,
  TransferPinResetModalName,
} from 'store/actions/ModalActions/ModalActionTypes';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCloseAction } from 'store/actions/ModalActions/ModalCloseAction';
import { ModalOpenAction } from 'store/actions/ModalActions/ModalOpenAction';
import { TextFieldFormik } from '../TextFieldFormik/TextFieldFormik';

import {
  Actions,
  MinWidth,
  ReceiveCode,
  RecendCode,
  TransferVerificationContainer,
  TransferVerificationContent,
  VerificationContent,
} from './styles';
import {
  useTransferFinalApiForConfirmationMutation,
  useAdminGenerateSmsMutation,
  useCancelTransferMutation,
} from 'services/Transfer';
import expiresVerificationTransderTime from 'jsx/hooks/expiresVerificationTransderTime';
import swal from 'sweetalert';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';

const TransferVerificationModal = ({ content }) => {
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelBtnLoading, setIsCancelBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const [errorDetails, setErrorDetails] = useState({});

  const { mutateAsync: cancelTransfer } = useCancelTransferMutation();
  const { mutateAsync: generateOtp } = useAdminGenerateSmsMutation();

  const { mutateAsync: finalApiForConfirmation } =
    useTransferFinalApiForConfirmationMutation();

  const { expiresTransferTime } = expiresVerificationTransderTime(
    content?.time
  );

  const resendOtp = async () => {
    setIsLoading(true);
    try {
      const response = await generateOtp(content.transcationId);
    } catch (error) {
      swal({
        title: 'Error',
        icon: 'error',
        text: error.response?.data?.errors[0]?.message,
        button: 'Try Again',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleTransferVerificationSubmit = async (values) => {
    setIsLoading(true);
    try {
      const finalApiForConfirmationData = await finalApiForConfirmation({
        data: {
          transferId: content.transcationId,
          otp: values.sms,
          phoneNumber: content.phoneNumber,
        },
      });
      if (finalApiForConfirmationData.status === 200) {
        dispatch(
          ModalOpenAction({
            modalName: TransferPaymentSentModalName,
            content: {
              fee: content?.fee,
              amount: finalApiForConfirmationData.data?.amount,
              currency: finalApiForConfirmationData.data?.currency,
            },
          })
        );
      }
    } catch (error) {
      swal({
        title: 'Error',
        icon: 'error',
        text: error.response?.data?.message,
        button: 'Try Again',
      }).then((res) => {
        if (res) {
          dispatch(ModalCloseAction());
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelTransaction = async () => {
    setIsCancelBtnLoading(true);
    try {
      const response = await cancelTransfer(content.transcationId);
      if (response) {
        dispatch(ModalCloseAction());
        swal({
          title: 'Success',
          icon: 'success',
          text: `Transfer cancelled successfully.`,
          buttons: 'Close',
        });
      }
    } catch (error) {
      setOpenDetailModal(false);
      setErrorDetails(error);
    } finally {
      setIsCancelBtnLoading(false);
    }
  };

  const handleModalClose = () => {
    setOpenDetailModal(false);
  };
  const handleCancelModal = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDetailModal(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          sms: '',
        }}
        validationSchema={transferSMSVerificationSchema}
        onSubmit={handleTransferVerificationSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={() => setErrorDetails({})}>
              <TransferVerificationContainer>
                <VerificationContent>
                  <Typography variant="selectOptionText">
                    Enter Your Verification Code
                  </Typography>
                  <TransferVerificationContent />
                  <Typography variant="phoneVerifyTitle">
                    We have sent a verification code to your email at{' '}
                    {content?.email} and via SMS to your phone number{' '}
                    {content?.phoneNumber}.
                  </Typography>
                  <MinWidth />
                  <Typography
                    variant="verifyTimeLeft"
                    mb="0.625rem"
                    mt="0.44rem"
                  >
                    {expiresTransferTime?.minutes == 0 &&
                    expiresTransferTime?.seconds == 0
                      ? 'Your code will not be valid'
                      : `Your code will not be valid in ${expiresTransferTime?.minutes} minutes ${expiresTransferTime?.seconds} seconds`}
                  </Typography>
                  <TextFieldFormik
                    type="text"
                    name="sms"
                    validateOnBlur={false}
                    placeholder="Please enter the code"
                    fullWidth
                  />
                  {errorDetails && (
                    <span style={{ color: 'red' }}>{errorDetails.message}</span>
                  )}
                  <Typography mb="0.625rem" />

                  {/*<TextFieldFormik*/}
                  {/*  type="text"*/}
                  {/*  name="comment"*/}
                  {/*  validateOnBlur={false}*/}
                  {/*  placeholder="Add comment"*/}
                  {/*  fullWidth*/}
                  {/*/>*/}
                  {content?.otp && (
                    <Typography variant="verifyTimeLeft">
                      Your code is: {content?.otp} (SMS are sent on production
                      environments only)
                    </Typography>
                  )}
                  <ReceiveCode>
                    <Typography variant="didntReceiveSms">
                      Did not receive SMS?
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <RecendCode>
                      <a onClick={resendOtp}>Resend a code</a>
                    </RecendCode>
                  </ReceiveCode>
                </VerificationContent>
                <Actions>
                  <LoadingButton
                    variant="contained"
                    sx={{
                      maxWidth: '9.375rem',
                    }}
                    type="submit"
                    loading={isLoading}
                  >
                    Confirm
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    aria-describedby="simple - popover"
                    color="secondary"
                    sx={{
                      maxWidth: '9.375rem',
                    }}
                    loading={isCancelBtnLoading}
                    onClick={handleCancelModal}
                  >
                    Cancel
                  </LoadingButton>
                  <Popover
                    id="simple - popover"
                    open={openDetailModal}
                    anchorEl={anchorEl}
                    onClose={handleModalClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    sx={{ zIndex: 1402 }}
                  >
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ mr: 2 }}>
                        Are you sure want to cancel?
                      </Typography>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={handleCancelTransaction}
                        sx={{ mr: 1 }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={handleModalClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    {/*<Typography sx={{ p: 2 }}>*/}
                    {/*  Are you sure want to cancel?*/}
                    {/*</Typography>*/}
                  </Popover>
                </Actions>
              </TransferVerificationContainer>
            </Form>
          );
        }}
      </Formik>
      {/*<Modal*/}
      {/*  show={openDetailModal}*/}
      {/*  onHide={handleModalClose}*/}
      {/*  centered*/}
      {/*  size="md"*/}
      {/*  style={{*/}
      {/*    backgroundColor: modalBackDropColor,*/}
      {/*    zIndex: 2000, // Increased z-index value*/}
      {/*  }}*/}
      {/*>*/}
      {/*  /!*<AccCreationModalBody style={{ padding: '1rem' }}>*!/*/}
      {/*  /!*  <div*!/*/}
      {/*  /!*    style={{*!/*/}
      {/*  /!*      display: 'flex',*!/*/}
      {/*  /!*      justifyContent: 'space-between',*!/*/}
      {/*  /!*      flexDirection: 'column',*!/*/}
      {/*  /!*      maxHeight: '100px',*!/*/}
      {/*  /!*      height: '100%',*!/*/}
      {/*  /!*      alignItems: 'center',*!/*/}
      {/*  /!*      width: '100%',*!/*/}
      {/*  /!*    }}*!/*/}
      {/*  /!*  >*!/*/}
      {/*  /!*    <h3>Are you sure want to cancel?</h3>*!/*/}
      {/*  /!*    <div*!/*/}
      {/*  /!*      style={{*!/*/}
      {/*  /!*        display: 'flex',*!/*/}
      {/*  /!*        justifyContent: 'center',*!/*/}
      {/*  /!*        alignItems: 'center',*!/*/}
      {/*  /!*        width: '100%',*!/*/}
      {/*  /!*      }}*!/*/}
      {/*  /!*    >*!/*/}
      {/*  /!*      <Actions>*!/*/}
      {/*  /!*        <LoadingButton*!/*/}
      {/*  /!*          variant="contained"*!/*/}
      {/*  /!*          sx={{*!/*/}
      {/*  /!*            maxWidth: '9.375rem',*!/*/}
      {/*  /!*          }}*!/*/}
      {/*  /!*          type="submit"*!/*/}
      {/*  /!*          loading={isLoading}*!/*/}
      {/*  /!*          onClick={handleCancelTransaction}*!/*/}
      {/*  /!*        >*!/*/}
      {/*  /!*          Yes*!/*/}
      {/*  /!*        </LoadingButton>*!/*/}
      {/*  /!*        <Button*!/*/}
      {/*  /!*          variant="contained"*!/*/}
      {/*  /!*          color="secondary"*!/*/}
      {/*  /!*          sx={{*!/*/}
      {/*  /!*            maxWidth: '9.375rem',*!/*/}
      {/*  /!*          }}*!/*/}
      {/*  /!*          onClick={handleModalClose}*!/*/}
      {/*  /!*        >*!/*/}
      {/*  /!*          No*!/*/}
      {/*  /!*        </Button>*!/*/}
      {/*  /!*      </Actions>*!/*/}
      {/*  /!*    </div>*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*</AccCreationModalBody>*!/*/}
      {/*</Modal>*/}
    </>
  );
};

export default TransferVerificationModal;
