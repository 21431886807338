import { useEffect, useState } from 'react';

const expiresVerificationTransderTime = (expiriesTime) => {
  const expTime = Math.floor(Date.parse(expiriesTime) / 1000);
  console.log('Tst', expTime);
  let time = Math.floor(Date.now() / 1000);
  const [expiresTransferTime, setExpiresTransferTime] = useState({
    minutes: 0,
    seconds: 0,
  });

  let timeLeft = expTime - time;
  let sec_num = parseInt(timeLeft, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  useEffect(() => {
    const interval = setInterval(() => {
      setExpiresTransferTime({ minutes, seconds });
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);
  return { expiresTransferTime };
};

export default expiresVerificationTransderTime;
