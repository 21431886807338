import { Typography } from '@mui/material';
import { transactionStatus } from '../Constant/transactionStatus';
import { ScheduleListIcon } from '../../SchedulePayment/ScheduleListIcon';

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.substring(1);
};
export const TransactionStatus = ({ transaction_status, transaction_meta }) => {
  switch (capitalize(transaction_status)) {
    case transactionStatus.COMPLETED: {
      if (transaction_meta?.scheduleStatus === 'Scheduled') {
        return (
          <Typography fontSize={18} fontWeight={600} className="text-dark">
            <ScheduleListIcon width={22} height={22} />
          </Typography>
        );
      }
      return (
        <Typography fontSize={18} fontWeight={600} className="text-primary">
          Success
        </Typography>
      );
    }
    case transactionStatus.REJECTED:
    case transactionStatus.DROPPED:
    case transactionStatus.FAILURE: {
      return (
        <Typography fontSize={18} fontWeight={600} className="text-red">
          Failure
        </Typography>
      );
    }
    case transactionStatus.CANCELED: {
      return (
        <Typography fontSize={18} fontWeight={600}>
          Canceled
        </Typography>
      );
    }
    default: {
      return (
        <Typography fontSize={18} fontWeight={600} className="text-dark">
          Pending
        </Typography>
      );
    }
  }
};
