import { Box, Button, Stack, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { SelectFormik } from 'jsx/components/SelectCustom/SelectFormik';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import debounce from 'lodash.debounce';
import { useEffect, useRef } from 'react';
import { internationalStep1Schema } from 'schemas/sendMoneyInternational.schema';
import { transactionCurrencyMapper } from 'services/Ledger';
import { useGetBanks } from 'services/SendMoney';
import { internationalCountries } from '.';
import {
  InternationalStep1Container,
  InternationalStep1Content,
} from './styles';
import PhoneInputFormik from 'jsx/components/PhoneInput/PhoneInputFormik';
import { TRANSFER_COUNTRY_TYPE } from 'jsx/components/SelectCustom/MenuItems';

const InternationalStep1 = ({ store, dispatchs, nextStep, prevStep }) => {
  const handleStep1Submit = (values) => {
    dispatchs.sendMoneyInternationalValues(values);
    nextStep();
  };

  const formik = useFormik({
    initialValues: {
      transferToCountry: '',
      transferToBank: '',
      transferToAccountName: '',
      transferTo: '',
      transferToNumber: '',
      transferAmount: '',
      transferConversionRate: '',
      transferConversionFee: '',
      transferConversionAmount: '',
      currency: '',
      description: '',
    },
    validationSchema: internationalStep1Schema(store?.ledgersOptions || []),
    onSubmit: handleStep1Submit,
  });

  const { data: bankList = [] } = useGetBanks(formik.values.transferToCountry);
  const bankListOptions = bankList.map((bank) => ({
    label: bank.name,
    value: bank._id,
    country: bank.country,
  }));

  const getConversionAmount = useRef(
    debounce(
      ({ amount, transferToCountry }) =>
        dispatchs
          .getConversionAmount({ amount, transferToCountry })
          .then(({ data: res }) => {
            const convertedCurrency = internationalCountries.find(
              (country) => country.value === transferToCountry
            )?.currency;

            formik.setFieldValue(
              'transferConversionAmount',
              convertedCurrency + res.data.conversion
            );
            formik.setFieldValue(
              'transferConversionRate',
              convertedCurrency + res.data.rate['$numberDecimal']
            );
            formik.setFieldValue('transferFee', res.data.fee['$numberDecimal']);
          }),
      1000
    )
  ).current;

  useEffect(() => {
    if (formik.values.transferAmount) {
      getConversionAmount({
        amount: formik.values.transferAmount,
        transferToCountry: formik.values.transferToCountry,
      });
    }
  }, [formik.values.transferAmount, formik.values.transferToCountry]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <InternationalStep1Container>
          <Typography variant="sendMoneyTitle" mt="3.125rem" mb="2.812rem">
            Send to International
          </Typography>
          <InternationalStep1Content>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                mb: '1.69rem',
                zIndex: 2,
                gap: '20px',
              }}
            >
              <SelectFormik
                placeholder="Choose a country"
                label="Transfer To"
                name="transferToCountry"
                options={internationalCountries}
                enablePortal
                itemType={TRANSFER_COUNTRY_TYPE}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                mb: '1.69rem',
                zIndex: 2,
                gap: '20px',
              }}
            >
              <SelectFormik
                placeholder="Send to"
                label="Bank Name"
                name="transferToBank"
                options={bankListOptions}
                enablePortal
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                mb: '1.69rem',
                zIndex: 1,
                gap: '20px',
              }}
            >
              <Box sx={{ width: '100%', maxWidth: '386px' }}>
                <TextFieldFormik
                  placeholder="Enter the Account Name"
                  label="ACCOUNT NAME"
                  name="transferToAccountName"
                  dashboard
                  fullWidth
                />
              </Box>
              <Box sx={{ width: '100%', maxWidth: '386px' }}>
                <TextFieldFormik
                  placeholder="Enter the Account Number"
                  label="ACCOUNT NUMBER"
                  name="transferTo"
                  dashboard
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                mb: '1.69rem',
                zIndex: 1,
                gap: '20px',
              }}
            >
              <Box sx={{ width: '100%', maxWidth: '386px' }}>
                <TextFieldFormik
                  placeholder="Enter the amount"
                  label="TRANSFER AMOUNT"
                  name="transferAmount"
                  dashboard
                  fullWidth
                  disabled={!formik.values.transferToCountry}
                />
              </Box>
              <SelectFormik
                placeholder="Select a currency"
                label="Currency"
                name="currency"
                options={store?.currencyOptions}
                enablePortal
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  '&': {
                    flex: 1,
                    '& div': {
                      '& p': {
                        color: '#788CA5',
                        mb: 0,
                        fontSize: 12,
                        fontWeight: 400,
                      },
                    },
                  },
                }}
              >
                <PhoneInputFormik
                  name="transferToNumber"
                  label="PHONE NUMBER"
                  enableAll
                />
              </Box>

              <Box sx={{ width: '100%', maxWidth: '24.125rem' }}>
                <TextFieldFormik
                  placeholder="Enter description"
                  label="DESCRIPTION"
                  name="description"
                  type="text"
                  dashboard
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
                zIndex: 1,
                mt: 2,
              }}
            >
              {formik.values.transferConversionRate && (
                <Stack direction="row">
                  <Typography variant="h6" fontWeight="bold" mr={3}>
                    Exchange Rate
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" minWidth={100}>
                    {formik.values.transferConversionRate}
                  </Typography>
                </Stack>
              )}
              {formik.values.transferConversionAmount && (
                <Stack direction="row">
                  <Typography variant="h6" fontWeight="bold" mr={3}>
                    Payable Amount
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" minWidth={100}>
                    {formik.values.transferConversionAmount}
                  </Typography>
                </Stack>
              )}
              {formik.values.transferFee && formik.values.currency && (
                <Stack direction="row">
                  <Typography variant="h6" fontWeight="bold" mr={3}>
                    Transfer Fee
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" minWidth={100}>
                    {transactionCurrencyMapper[formik.values.currency]}{' '}
                    {formik.values.transferFee}
                  </Typography>
                </Stack>
              )}
            </Box>

            <Stack direction="row" gap={6} justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  maxWidth: '11.38rem',
                  alignSelf: 'end',
                  mt: '2.81rem',
                }}
                onClick={prevStep}
              >
                Prev
              </Button>
              <Button
                variant="contained"
                sx={{
                  maxWidth: '11.38rem',
                  alignSelf: 'end',
                  mt: '2.81rem',
                }}
                type="submit"
              >
                Next
              </Button>
            </Stack>
          </InternationalStep1Content>
        </InternationalStep1Container>
      </Form>
    </FormikProvider>
  );
};
export default InternationalStep1;
